<template>
  <b-row>
    <b-col class="chat">
      <b-row class="border-bottom sticky-top bg-white page-header">
        <div class="d-flex align-items-center w-100 px-3">
          <b-link @click="$router.go(-1)">
            <i :class="$store.isRTL ? 'bi-arrow-right' : 'bi-arrow-left'" />
          </b-link>
          <h5 class="p-0 my-3 mx-2 flex-grow-1 overflow-hidden d-flex">
            <b-avatar
              :src="party ? party.avatar : ''"
              :to="party ? party.url : ''"
              class="bg-secondary text-white"
              :class="$store.isRTL ? 'ml-2' : 'mr-2'"
            /> 
            <ui-username
              :user="party"
              v-if="party"
              :asLink="false"
              :bold="false"
            />
            <span v-else>&nbsp;</span>
          </h5>
          <b-link @click.prevent="tip" class="mx-1 my-1 d-flex flex-column" v-if="!isOwner && $store.state.paymentEnabled" >
            <ui-icon name="coin" classes="icon-medium"></ui-icon>
            <span class="link-text">{{ $t('general.send-tip') }}</span>
          </b-link>
        </div>
      </b-row>
      <b-row class="conversation" ref="conversation">
        <div class="wrapper px-3">
          <div v-for="(message, index) in messages" :key="index">
            <div
              class="timeline" v-if="index == messages.length - 1 ||
                (index < messages.length - 1 &&
                  !messages[index + 1].created_at.isSame(
                    message.created_at,
                    'day'
                  ))
              "
            >
              <div class="small text-muted bg-white d-inline">
                {{ message.dayRelated }}
              </div>
            </div>
            <ui-message v-model="messages[index]" :index="index" :party="party" v-if="message.type != 'chat-tip' && message.type != 'tip'" @input="updateMessage" @completed="loadedCompleted"/>
            <div class="timeline my-1" v-else>
              <div class="medium text-muted bg-white d-inline d-flex">
                {{ message.message }}<ui-icon name="coin" classess="icon-small"></ui-icon> 
              </div>
            </div>
          </div>
        </div>
      </b-row>
      <b-row class="border-top message-input-container">
        <div class="d-flex align-items-center w-100">
          <b-form-textarea
          rows="2"
          max-rows="4"
          class="py-0 message-input"
          v-model="message"
          :placeholder="$t('general.type-message')"
        />
        
          <b-link
            v-b-modal.modalPrice
            class="mx-2 border-0"
            v-if="currentUser.isCreator"
          >
            <i class="bi-tag" />
          </b-link>
          <ui-post-option-info
            v-if="price > 0"
            :info="priceFormat()"
            @close="priceRemove()"
          />
          <b-button
            class="border-0"
            variant="outline-light"
            @click.prevent="sendMessage"
            :disabled="sendingMessage"
          >
            <ui-icon name="angle-circle-left" classes="icon-medium"></ui-icon>
          </b-button>
        </div>
      </b-row>
    </b-col>
  </b-row>
</template>
<style scoped>
html{
  overflow: hidden !important;
  height: calc(var(--vh, 1vh)*100);
  display: flex;
  margin-right: 0!important;
}
</style>
<style scoped lang="scss">
@import "~@/assets/scss/_variables.scss";
.link-text{
  font-family: 'almoni-tzar', sans-serif;
  margin-top: -3px;
}
textarea.form-control {
  border: none;
  scroll-behavior: smooth;
}
textarea.form-control:focus {
  border: none;
  box-shadow: none;
}
.chat {
  min-height: calc(var(--vh, 1vh) * 100);
  max-height: calc(var(--vh, 1vh) * 100);
  flex-direction: column;
  display: flex;
  .conversation {
    overflow-y: auto;
    overscroll-behavior: none;
    flex: 1;
    display: flex;
    padding-bottom: 1rem;
    padding-top: 6rem;
    .wrapper {
      width: 100% !important;
      margin-top: auto;
      flex-direction: column-reverse;
      display: flex;
    }
  }
  .timeline {
    position: relative;
    display: flex;
    justify-content: center;
    &::before {
      content: "";
      position: absolute;
      height: 1px;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      background-color: gray("300");
    }
    & > div {
      position: relative;
      z-index: 5;
      padding: 0 $spacer;
    }
  }
}
@include media-breakpoint-down(xs) {
  .chat {
    min-height: calc(var(--vh, 1vh) * 100 - (#{$spacer} * 4));
    max-height: calc(var(--vh, 1vh) * 100 - (#{$spacer} * 4));
  }

  .message-input-container{
    position: fixed !important;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1001;
    padding-bottom: 10px;
    margin: 0;
    padding-right: 10px;
    padding-left: 10px;
    widows: 100% !important;
    background-color: white;
  }
}
</style>
<script>
import Message from "../models/Message";
import UiMessage from "../ui/UiMessage.vue";
import UiPostOptionInfo from "../ui/UiPostOptionInfo.vue";
import User from "../models/User";
import UiUsername from "../ui/UiUsername.vue";
import UiIcon from '../ui/UiIcon.vue';
import Payment from "../models/Payment";

export default {
  props: {
    value: Array,
    chatId: Number,
  },
  data: function () {
    return {
      messages: [],
      price: null,
      errors: {},
      message: "",
      media: [],
      party: null,
      page: 1,
      hasMore: false,
      isLoading: false,
      addedManually: 0,
      attachmentsCount: 0,
      attachmentLoadCount: 0
    };
  },
  computed: {
    currency() {
      return process.env.VUE_APP_CURRENCY_SIGN;
    },
    currentUser() {
      return this.$store.state.currentUser;
    },
    isOwner: function () {
      return this.chatId == this.$store.state.currentUser.id;
    },
    chats: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {
    chatId:{
      immediate: false,
      handler(){
        this.init();
      }
    },
    attachmentLoadCount: {
      immediate: false,
      handler(){
        if(this.attachmentLoadCount == this.attachmentsCount){
          this.$refs.conversation.scrollTo({
            top: this.$refs.conversation.scrollHeight,
            behavior: "instant",
          });
        }
      }
    },
    '$store.state.reloadChat': {
      immediate: true,
      handler(){
        if(this.$store.state.reloadChat == true){
            this.messages =[];
            this.reloadFirst();
            this.$store.state.reloadChat = false;
          }
      }
    },
    '$route.query':{
      immediate: true,
      handler(){
        let query = this.$route.query;
        if(Object.keys(query).length === 0) return;

        if(!query.success || query.success !== "1"){
          this.$bvToast.toast(query.message, {
            autoHideDelay: 2000,
            title: this.$t("general.error"),
            solid: true,
            variant: 'danger',
            toaster: "b-toaster-top-left",
          });
          
        }else{
          this.$bvToast.toast(query.message, {
            autoHideDelay: 2000,
            title: this.$t("general.success"),
            solid: true,
            variant: 'success',
            toaster: "b-toaster-top-left",
          });
          
        }

        this.$router.push({query:null});
      }
    }
      
  },
  mounted() {
    this.init();
    setTimeout(()=>{
      this.$refs.conversation.addEventListener("scroll", this.updateScroll);
    },999);
    window.scrollTo(0, document.body.scrollHeight);
  },
  components: {
    UiMessage,
    UiPostOptionInfo,
    UiUsername,
    UiIcon
  },
  methods: {
    async init() {
      this.messages = [];
      this.price = null;
      this.errors = {};
      this.message = "";
      this.media = [];
      this.party = null;
      let chats = [...this.chats];
      for (let c of chats) {
        if (c.party.id == this.chatId) {
          this.party = c.party;
          c.message.isRead = true;
        }
      }
      
      this.page = 1;
      this.hasMore = false;
      this.isLoading = false;
      this.chats = chats;
      this.loadMessages();
    },
    updateScroll() {
      if (
        this.$refs.conversation &&
        this.$refs.conversation.scrollTop <= 200 &&
        !this.isLoading &&
        this.hasMore
      ) {
        this.loadMore();
      }
    },
    updateMessage(e){
      this.messages[e.index] = e.message;
    },
    readMessages() {
      for (let m of this.messages) {
        m.isRead = true;
      }
    },
    loadMessages(page) {
      this.isLoading = true;
      this.loadedCount = 0;
      this.$get(
        "/messages/" + this.chatId + "?page=" + (page ? page : this.page),
        (data) => {

          const recent = this.messages.length > 0 ? this.messages[0].created_at : null;
          parser: for (let obj of data.messages.data) {
            
            obj.currentUser = this.$store.state.currentUser;

            if(obj.has_attachments || obj.attachments.length > 0){
              this.attachmentsCount++;
            }

            const m = new Message(obj, this);

            for (let mm of this.messages) {
              if (mm.id == m.id) {
                continue parser;
              }
            }
            if (!recent || m.created_at.isBefore(recent)) {
              this.messages.push(m);
            } else {
              this.addMessage(m);
            }

            
          }


          this.isLoading = false;
          if (!page) {
            this.hasMore = data.messages.next_page_url != null;
          }
          this.party = this.party ? this.party : new User(data.contact);


          if (this.page == 1) {
            this.$nextTick(function () {
              this.$refs.conversation.scrollTo({
                top: this.$refs.conversation.scrollHeight,
                behavior: "instant",
              });
            });
          }

          
        },
        (errors) => {
          console.log(errors);
        }
      );
    },
    reloadFirst() {
      this.loadMessages(1);
    },
    loadMore() {
      if (this.hasMore) {
        this.page = this.page + 1;
        if (this.addedManually >= 20) {
          this.page = this.page + 1;
          this.addedManually = 0;
        }
        this.loadMessages();
      }
    },
    mediaDropzoneClick() {
      this.$refs.uploader.click();
    },
    priceFormat() {
      return this.currency + this.price;
    },
    priceRemove() {
      this.price = null;
    },
    addMessage(message) {
      this.messages.unshift(message);
      if (message.user.id != this.$store.state.currentUser.id) {
        message.isRead = true;
      }

      let valid = [];
      let chats = [...this.chats];
      let found = false;
      for (let c of chats) {
        if (c.party.id == this.party.id) {
          c.message = message;
          found = true;
          valid.unshift(c);
        } else {
          valid.push(c);
        }
      }
      if (!found) {
        valid.unshift({
          party: this.party,
          message: message,
        });
      }
      this.chats = valid;
      this.$nextTick(function () {
        this.$refs.conversation.scrollTo({
          top: this.$refs.conversation.scrollHeight,
          behavior: "smooth",
        });
      });

      this.addedManually++;
    },
    sendMessage() {
      this.errors = {};

      let media = [];
      for (let m of this.media) {
        media.push({
          id: m.id,
          screenshot: m.scr ? m.scr.id : null,
        });
      }

      this.errors = {};
      let fields = {
        message: this.message,
      };
      
      if (media.length > 0) {
        fields.media = media;
      }
      if (this.price) {
        fields.price = this.price;
      }

      let message = new Message(fields,this);
      this.addMessage(message);
      this.message = "";
      this.media = [];
      this.price = null;

      this.$post(
        "/messages/" + this.party.id,
        fields,
        () => {},
        (errors) => {
          if(errors.length > 0){
            for(let error of errors.message){
              this.$bvToast.toast(error, {
                autoHideDelay: 2000,
                title: this.$t("general.error"),
                solid: true,
                variant: 'danger',
                toaster: "b-toaster-top-left",
              });
            }
          }
        }
      );
    },
    tip() {
      this.$buyItem({
        type: Payment.TYPE_CHAT_TIP,
        user: this.party,
        success: (data) =>{
          this.$bvToast.toast(this.$t('general.sent_x_tip', [data.message.tip_amount]), {
              autoHideDelay: 2000,
              variant: 'success',
              title: this.$t("general.success"),
              solid: true,
              toaster: "b-toaster-top-left",
              noCloseButton: true
          });

          this.loadMessages();
        }
      });
    },
    loadedCompleted(){
      this.attachmentLoadCount++;
    }
  },
};
</script>
