<template>
  <b-row class="profile" v-if="user != null " :class="$store.isRTL ? 'text-right' : ''">
    <b-col>
      <b-row
        class="page-header sticky-top bg-white border-bottom scrolled"
      >
        <div class="d-flex align-items-center w-100 px-2">
          <h5 class="p-0 my-3 mx-2 flex-grow-1 overflow-hidden">
            <ui-username :user="user" :asLink="false" :bold="false" />
          </h5>
          <b-link @click="$store.state.showPublicPagePopup = true" class="mx-1 my-1 d-flex flex-column justify-content-center text-center">
            <ui-icon name="plus" classes="icon-medium"></ui-icon>
            <span class="link-text">{{ $t('general.send-message-user') }}</span>
          </b-link>
        </div>
      </b-row>
      <b-row>
        <div class="bg-light cover w-100 position-relative">
          <b-img :src="user.cover" v-if="user.cover != null" />
        </div>
        <div class="subprofile w-100 d-flex">
          <div class="avatar position-relative ml-3 rounded-circle">
            <b-avatar
              :src="user.avatar"
              :text="user.initials"
              :to="user.url"
              size="100px"
            />
          </div>
        </div>
      </b-row>
      <b-row class="px-3 mt-3 d-block border-bottom pb-3 overflow-hidden">
        <ui-username :user="user" :asLink="false" />
        <div class="text-muted small username">
          {{ "@" + user.username }}
        </div>
        <div class="bio nl2br" v-if="user.bio">{{ user.bio }}</div>
        <div v-if="user.location" class="text-muted small">
          {{ user.location }}
        </div>
      </b-row>
      <b-row class="px-3 mt-3 d-block border-bottom pb-3">
        <h5 class="mb-3">{{ $t("general.subscription") }}</h5>
        <b-button v-if="user.isFree" variant="primary" block @click="$store.state.showPublicPagePopup = true">
          {{$t("general.follow")}}
        </b-button>
        <b-button v-else variant="primary" block @click="$store.state.showPublicPagePopup = true">
          {{ $t("general.get-x-months-for-y", user.bundles[0].title) }}
        </b-button>
      </b-row>
      <ui-posts v-model="posts" />
    </b-col>

    <div>

    <b-modal id="modal-show-popup" size="lg" v-model="$store.state.showPublicPagePopup" centered :title="popupTitle" hide-footer>
        <b-row>
          <b-col class="d-none d-md-block">
            <ui-vertical-card-member :user="user"></ui-vertical-card-member>
          </b-col>
          <b-col>
            <ui-form-login v-if="showLogin" @switchForm="switchForm"></ui-form-login>
            <ui-form-register v-else-if="showRegister" @switchForm="switchForm"></ui-form-register>
          </b-col>
        </b-row>
      </b-modal>
    </div>

  </b-row>
  <b-row v-else-if="isLoading == false && user == null">
    <b-col class="text-center my-5">
      <h1 class="display-1">404</h1>
      <p class="lead">Page Not Found</p>
      <b-button variant="primary" href="/">Back to Home</b-button>
    </b-col>
  </b-row>
</template>
<style lang="scss" scoped>
.link-text{
  font-family: 'almoni-tzar', sans-serif;
  margin-top: -3px;
}
.profile {
  .page-header {
    top: -56px;
    margin-bottom: -56px;
    transition: top 0.2s;
    color: white;
    .btn,
    a:not(.dropdown-item) {
      padding: 0;
      color: white;
    }
    &.scrolled {
      top: 0;
      margin-bottom: 0;
      color: black;
      .btn,
      a {
        color: black;
      }
    }
  }
  .cover {
    height: 180px;
    &:after {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      pointer-events: none;
      content: "";
      z-index: 3;
    }
    img {
      -o-object-fit: cover;
      object-fit: cover;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
  .avatar {
    margin-top: -50px;
    z-index: 3;
  }
}
</style>
<style scoped lang="scss">
@import "~@/assets/scss/_variables.scss";
.nav-tabs .nav-link {
  border: none;
  border-bottom: 2px solid transparent;
  border-radius: 0;
  color: $secondary;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: $primary;
  border-color: $primary;
}
</style>
<script>
import Post from "../models/Post";
import User from "../models/User";
import UiFormLogin from '../ui/UiFormLogin.vue';
import UiFormRegister from "../ui/UiFormRegister.vue";
import UiPosts from "../ui/UiPosts.vue";
import UiUsername from "../ui/UiUsername.vue";
import UiIcon from "../ui/UiIcon.vue";
import axios from "axios";
import UiVerticalCardMember from "../ui/UiVerticalCardMember.vue";

export default {
  components: { UiIcon, UiPosts, UiUsername, UiFormLogin, UiFormRegister, UiVerticalCardMember },
  data: function () {
    return {
      user: null,
      posts: [],
      scrollPosition: null,
      page: 1,
      hasMore: false,
      isLoading: false,
      postsType: Post.TYPE_ACTIVE,
      showLogin: true,
      showRegister: false,
      popupTitle: this.$t('general.login')
    };
  },
  computed: {
    isScrolled: function () {
      return this.scrollPosition >= 180;
    },
    username: function () {
      return this.$route.params.username;
    },
    types: function () {
      return {
        active: Post.TYPE_ACTIVE,
        scheduled: Post.TYPE_SCHEDULED,
        expired: Post.TYPE_EXPIRED,
      };
    },
  },
  watch: {
    username: function (oldV, newV) {
      if (oldV && newV && oldV != newV) {
        this.reset();
        this.loadUser();
      }
    },
  },
  mounted() {
    this.$store.state.currentUser = {}
    this.loadUser();
    window.addEventListener("scroll", this.updateScroll);
  },
  methods: {
    updatePosts(type) {
      this.postsType = type;
      this.reset();
      this.loadPosts();
    },
    reset() {
      this.isLoading = false;
      this.hasMore = false;
      this.page = 1;
      this.posts = [];
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
      const scrollPosition = window.innerHeight + window.scrollY;
      if (
        document.body.offsetHeight &&
        scrollPosition &&
        document.body.offsetHeight - scrollPosition <= 1000 &&
        !this.isLoading &&
        this.hasMore
      ) {
        this.loadMore();
      }
    },
    loadUser() {
      this.isLoading = true;
      axios.get(process.env.VUE_APP_API_URL + '/' + process.env.VUE_APP_API_VERSION + '/public-user/'+ this.username, {
        validateStatus: function (status) {
          return status >= 200 && status < 300 || status === 404;
        }
      }).then((response)=>{
        if(response.data.success){
          this.user = new User(response.data.profile);
          this.loadPosts();
        }else{
          this.isLoading = false;
        }
        
      }).catch((error) => {
        console.log(error);
      });
    },
    loadPosts() {
      this.isLoading = true;
      axios.get(process.env.VUE_APP_API_URL + '/' + process.env.VUE_APP_API_VERSION + '/public-post/'+ this.username + '?page=' + this.page, {
        validateStatus: function (status) {
          return status >= 200 && status < 300 || status === 404;
        }
      }).then((response)=>{
        let posts = [...this.posts];
        for (let obj of response.data.data) {
          posts.push(new Post(obj));
        }
        this.posts = posts;
        this.hasMore = response.next_page_url != null;
        this.isLoading = false;
      }).catch((error) => {
        console.log(error);
      });
    },
    loadMore() {
      if (this.hasMore) {
        this.page = this.page + 1;
        this.loadPosts();
      }
    },
    switchForm(value){
      this.showLogin = value.login;
      this.showRegister = value.register;

      if(this.showLogin){
        this.popupTitle = this.$t('general.login');
      }else if(this.showRegister){
        this.popupTitle = this.$t('general.signup');
      }
    }
  },
};
</script>
