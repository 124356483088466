<template>
    <div class="d-flex justify-content-center flex-column text-center">
        <a @click="skip" class="btn-skip pt-3 pl-3">דלג</a>
        <h5>להישאר תמיד מעודכן</h5>
        <icon class="w-50 mx-auto mt-4 mb-2"></icon>
        <h5 class="w-80 text-center mx-auto">הפעל את ההתראות כדי לקבל הודעות בזמן אמת</h5>
        
        <b-button class="w-50 mx-auto mt-5" variant="primary" @click="enableNotification">הפעל התראות</b-button>
    </div> 
</template>
<style scoped lang="scss">
@import "~@/assets/scss/_variables.scss";
.w-80 {
    width: 80% !important;
}

.btn-skip{
    position: absolute;
    top: 0;
    left: 0;
    color: #000;
}
</style>
<script>

import Icon from '@/assets/enable-notification.svg';

export default {
    components: {
        Icon
    },
    methods: {
        enableNotification: function(){
            this.$OneSignal.registerForPushNotifications();
        },
        skip: function(){
            this.$get('/push-notification/skip');
            this.$store.state.currentUser.push_notification_permission = 'denied';
        }
    }
};
</script>