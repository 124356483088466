<template>
  <b-row class="settings">
    <b-col>
      <b-row class="border-bottom sticky-top bg-white page-header">
        <div class="d-flex align-items-center w-100 mx-3 text-right">
          <b-link @click="$router.go(-1)">
            <i class="bi-arrow-right" />
          </b-link>
          <h5 class="text-uppercase p-0 my-3 mx-2 flex-grow-1">
            {{ title }}
          </h5>
        </div>
      </b-row>
      <b-row>
        <div class="inline-border-tabs text-bold my-2">
          <b-nav class="px-0">
            <b-nav-item :active="isActive('profile')" @click="switchSetting('profile')">
              <b-icon :icon="isActive('profile') ? 'person-fill':'person'"></b-icon>
            </b-nav-item>
            <b-nav-item :active="isActive('account')" @click="switchSetting('account')">
              <b-icon :icon="isActive('account') ? 'gear-fill':'gear'"></b-icon>
            </b-nav-item>
            <b-nav-item :active="isActive('transactions')" @click="switchSetting('transactions')" v-if="$store.state.paymentEnabled">
              <b-icon :icon="isActive('transactions') ? 'credit-card-fill':'credit-card'"></b-icon>
            </b-nav-item>
            <b-nav-item :active="isActive('subscriptions')" @click="switchSetting('subscriptions')" v-if="$store.state.paymentEnabled">
              <b-icon :icon="isActive('subscriptions') ? 'people-fill':'people'"></b-icon>
            </b-nav-item>
            <b-nav-item :active="isActive('followed')" @click="switchSetting('followed')">
              <b-icon :icon="isActive('followed') ? 'person-check-fill':'person-check'"></b-icon>
            </b-nav-item>
          </b-nav>
        </div>
      </b-row>
      
      <ui-settings-profile :is="currentComponent"></ui-settings-profile>
        
    </b-col>
  </b-row>
</template>
<style lang="scss">
.settings {
  .cover,
  .avatar {
    z-index: 2;
    &:after {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      pointer-events: none;
      content: "";
      z-index: 3;
    }
  }
  
  .cover {
    height: 180px;
    img {
      -o-object-fit: cover;
      object-fit: cover;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
  .buttons {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 11;
  }
  .avatar {
    margin-top: -50px;
    z-index: 3;
    &:after {
      border-radius: 50%;
    }
  }
}
</style>
<script>

import UiSettingsProfile from '../ui/UiSettingsProfile.vue';
import UiSettingsAccount from '../ui/UiSettingsAccount.vue';
import UiSettingsTransactions from '../ui/UiSettingsTransactions.vue';
import UiSettingsSubscriptions from '../ui/UiSettingsSubscriptions.vue';
import UiSettingsFollowed from '../ui/UiSettingsFollowed.vue';

export default {
  data(){
    return{
      currentComponent: UiSettingsProfile,
      activeComponentName: 'profile', //default
      title: this.$t("general.settings")
    }
  },
  components:{
    UiSettingsProfile,
    UiSettingsAccount,
    UiSettingsTransactions,
    UiSettingsSubscriptions,
    UiSettingsFollowed
  },
  created(){
    
    let params = this.$route.query;

    if(params.name != undefined){
      this.switchSetting(params.name)
    }
    
    if(params.success == false){
      this.$bvToast.toast(params.message, {
        autoHideDelay: 2000,
        title: this.$t("general.error"),
        solid: true,
        variant: 'danger',
        toaster: "b-toaster-top-left",
      });
    }else{
      this.$bvToast.toast(params.message, {
        autoHideDelay: 2000,
        title: this.$t("general.success"),
        solid: true,
        variant: 'success',
        toaster: "b-toaster-top-left",
      });
    }

    this.$router.replace({'query': {name: params.name}});

  },
  methods:{
    switchSetting(name){
      this.activeComponentName = name;
      switch(name){
        case 'profile':
          this.currentComponent = UiSettingsProfile;
          this.title = this.$t("general.profile")
          break;
        case 'account':
          this.currentComponent = UiSettingsAccount;
          this.title = this.$t("general.account")
          break;
        case 'transactions':
          this.currentComponent = UiSettingsTransactions;
          this.title = this.$t("general.payments")
        break;
        case 'subscriptions':
          this.currentComponent = UiSettingsSubscriptions;
          this.title = this.$t("general.subscriptions")
        break;
        case 'followed':
          this.currentComponent = UiSettingsFollowed;
          this.title = this.$t("general.following")
        break;
      }

      this.$router.push({path:this.$router.fullPath, query: {name}})
    },
    isActive(name){
      return this.activeComponentName == name ? true : false
    }
  }
};
</script>
