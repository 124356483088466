<template>
  <b-row ref="chats">
    <b-col
      lg="5"
      md="12"
      class="chats"
      :class="[showChatId != null || isComposing ? 'd-none d-lg-block' : '', $store.isRTL ? 'border-left' : 'border-right']"
    >
      <div>
        <b-row class="border-bottom sticky-top page-header bg-white">
          <div class="d-flex justify-content-start align-items-center w-100 mx-3">
            <b-link @click="$router.go(-1)">
              <i class="bi-arrow-right" />
            </b-link>
            <h5 class="text-uppercase p-0 my-3 mx-2">
              {{ $t("general.messages") }}
            </h5>
            <b-link to="/messages/compose" v-if="currentUser.isCreator">
              <i class="bi-plus-circle" />
            </b-link>
          </div>
        </b-row>
        <b-row 
          class="border-bottom"
          v-for="(chat, index) in chats"
          :key="index"
        >
          <div class="d-flex position-relative item w-100">
            <b-link class="link" :to="'/messages/' + chat.party.id">
              <b-avatar
                :text="chat.party.initials"
                :src="chat.party.avatar"
                class="bg-secondary text-white"
              />
              <ui-username
                :user="chat.party"
                :full="true"
                :asLink="false"
                :class="$store.isRTL ? 'pl-3' : 'pr-3'"
              />
              <div class="d-flex small body align-items-center">
                <div class="flex-grow-1 message" :class="$store.isRTL ? 'ml-1' : 'mr-1'">
                  {{ chat.message.message }}
                </div>
                <div
                  :class="
                    !chat.message.isRead && !isOwner(chat.message)
                      ? 'text-primary'
                      : 'text-muted'
                  "
                >
                  {{
                    chat.message.timeRelated
                      ? $t(chat.message.timeRelated)
                      : chat.message.timeNormal
                  }}
                </div>
                <i
                  class="bi-dot m-n2 text-primary"
                  v-if="!chat.message.isRead && !isOwner(chat.message)"
                  style="font-size: 300%; line-height: 1"
                />
              </div>
            </b-link>
          </div>
        </b-row>
        <b-row>
          <no-message-available class="mt-5 message-empty-promp" v-if="chats.length <= 0"></no-message-available>
        </b-row>
      </div>
    </b-col>
    <b-col
      lg="7"
      md="12"
      :class="showChatId != null || isComposing ? '' : 'd-none d-lg-block'"
    >
      <page-chat
        ref="chat"
        :chatId="showChatId"
        v-model="chats"
        v-if="showChatId != null && !isComposing"
      />
      
    </b-col>
  </b-row>
</template>
<style scoped lang="scss">
@import "~@/assets/scss/_variables.scss";
.list {
  cursor: pointer;
}
.mass-alert {
  background-color: lighten($primary, 50%);
}
</style>
<style lang="scss">
@import "~@/assets/scss/_variables.scss";
@include media-breakpoint-down(md) {
  .chats {
    border-right-width: 0px !important;
  }
}
@include media-breakpoint-up(lg) {
  .chats {
    border-right-width: 1px !important;
  }
}
.chats {
  min-height: calc(var(--vh, 1vh) * 100);
  .item {
    padding-left: 4rem;
    min-height: 4.5rem;
    align-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
      background-color: var(--light);
    }
    .b-avatar {
      position: absolute;
      left: 1rem;
      top: 50%;
      transform: translateY(-50%);
    }
    .x {
      position: absolute;
      right: 1rem;
      top: 1rem;
    }
    .link {
      display: block;
      color: inherit;
      padding: 0 1rem 0 0;
      flex: 1;
      width: calc(100% - 80px);
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
    }
    .body {
      white-space: nowrap;
      .message {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
@include media-breakpoint-down(xs) {
  .chats {
    min-height: calc(var(--vh, 1vh) * 100 - (#{$spacer} * 4));
  }
}
</style>
<script>
import Message from "../models/Message";
import User from "../models/User";
import UiUsername from "../ui/UiUsername.vue";
import PageChat from "./PageChat.vue";
import NoMessageAvailable from '../../assets/messages-empty.svg';

export default {
  components: {
    PageChat,
    UiUsername,
    NoMessageAvailable
  },
  data: function () {
    return {
      chats: [],
      mass: null,
      hasMore: false,
      isLoading: false,
      page: 1,
      showChatId: null,
      doCompose: false,
      addedManually: 0
    };
  },
  computed: {
    chatId() {
      return this.$route.params.id && this.$route.params.id != "compose"
        ? this.$route.params.id * 1
        : null;
    },
    isComposing() {
      return this.$route.params.id == "compose";
    },
    pusher() {
      return this.$store.state.pusher;
    },
    currentUser() {
      return this.$store.state.currentUser;
    },
  },
  watch: {
    chatId(v) {
      this.showChatId = v;
    },
    pusher() {
      this.listen();
    },
  },
  mounted() {
    this.loadChats();
    this.listen();
    window.onscroll = () => this.updateScroll()
  },
  beforeDestroy() {
    this.unlisten();
  },
  methods: {
    listen() {
      if (this.pusher) {
        this.pusher.bind("message", this.handleNewMessage);
        this.pusher.bind("message.read", this.handleMessageRead);
        this.pusher.bind(
          "message.mass.complete",
          this.handleMassMessageComplete
        );
      }
    },
    unlisten() {
      if (this.pusher) {
        this.pusher.unbind("message", this.handleNewMessage);
        this.pusher.unbind("message.read", this.handleMessageRead);
        this.pusher.unbind(
          "message.mass.complete",
          this.handleMassMessageComplete
        );
      }
    },
    handleNewMessage(data) {
      const newMessage = new Message(data.message, this);
      if (newMessage.user.id == this.showChatId) {
        this.$refs.chat.reloadFirst();
      } else {
        let valid = [];
        let chats = [...this.chats];
        let found = false;
        for (let c of chats) {
          if (c.party.id == newMessage.user.id) {
            c.message = newMessage;
            found = true;
            valid.unshift(c);
          } else {
            valid.push(c);
          }
        }
        if (!found) {
          valid.unshift({
            party: newMessage.user,
            message: newMessage,
          });
        }

        this.chats = valid;
      }
    },
    handleMessageRead(data) {
      if (data.id == this.showChatId) {
        this.$refs.chat.readMessages();
      }
    },
    handleMassMessageComplete(data) {
      const m = new Message(data.message, this);
      m.recipients_count = data.message.recipients_count;
      m.mass_complete = true;
      this.mass = m;
    },
    isOwner(message) {
      return message.user.id == this.$store.state.currentUser.id;
    },
    updateScroll() {
      console.log('scroll');
      if (
        this.$refs.chats &&
        this.$refs.chats.scrollTop <= 200 && !this.isLoading && this.hasMore
      ) {
        this.loadMore();
      }
    },
    loadMore() {
      if (this.hasMore) {
        this.page = this.page + 1;
        this.loadChats();
      }
    },
    loadChats(page) {
      this.isLoading = true;
      if (this.chats.length == 0) {
        this.$showSpinner();
      }
      this.$get(
        "/messages" + "?page=" + (page ? page : this.page),
        (data) => {

          for (let obj of data.chats.data) {

            obj.message.currentUser = this.$store.state.currentUser;

            this.chats.push({
              party: new User(obj.contact),
              message: new Message(obj.message, this),
            });
            
          }

          console.log(this.chats);
          // if (data.mass) {
          //   this.mass = new Message(data.mass);
          //   this.mass.recipients_count = data.mass.recipients_count;
          // }

          this.isLoading = false;

          if (!page) {
            this.hasMore = data.chats.next_page_url != null;
          }
          this.$nextTick(function () {
            this.showChatId = this.chatId;
          });
        },
        (errors) => {
          console.log(errors);
        }
      );
    },
    cleanChat(id) {
      this.$post(
        "/messages/" + id,
        {
          _method: "DELETE",
        },
        () => {
          this.loadChats();
          this.$router.replace("/messages");
        },
        (errors) => {
          console.log(errors);
        }
      );
    },
  },
};
</script>
