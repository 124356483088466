<template>
  <b-form-group
    :label="label"
    :label-for="name"
    class="mb-3"
    label-class="small"
  >
    <b-form-datepicker 
      :id="name"
      v-model="inputVal"
      :required="required"
      :disabled="disabled"
      :readonly="readonly"
      :placeholder="placeholder"
      :state="errors[name] ? false : null"
      :locale="locale"
      :direction="direction"
      show-decade-nav
    />
    <b-form-invalid-feedback
      :id="name + '-errors'"
      class="d-block"
      v-if="errors[name] && errors[name].length > 0"
    >
      <div v-for="error in errors[name]" :key="error">
        {{ error }}
      </div>
    </b-form-invalid-feedback>
  </b-form-group>
</template>
<script>
export default {
  props: [
    "label",
    "name",
    "value",
    "readonly",
    "errors",
    "disabled",
    "required",
    "placeholder",
    "locale",
    "direction"
  ],
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
