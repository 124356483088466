<template>
  <div class="post w-100 border-bottom" v-if="post != null">
    <div class="d-flex p-3 w-100 align-items-start nowrap">
      <b-avatar
        :text="post.user.initials"
        :src="post.user.avatar"
        :to="post.user.url"
        class="bg-secondary text-white"
      />

      <div class="flex-grow-1 px-2 overflow-hidden d-block align-items-center">
        <ui-username :user="post.user" />
        <b-link
          :to="'/' + post.user.username"
          class="small text-secondary username d-block"
          >@{{ post.user.username }}
        </b-link>
      </div>

      <!-- <div class="d-flex align-items-center">
        <div class="text-muted">{{ post.ago }}</div>
      </div> -->
    </div>
    <div class="px-3 pb-3 w-100 overflow-hidden nl2br wrap text-right">
      {{ post.message }}
    </div>
    <div class="media" v-if="post.media.length > 0 && hasAccess">
      <div ref="swiper" class="swiper w-100" v-if="post.media.length > 1">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide w-100"
            v-for="(item, key) in post.media"
            :key="key"
          >
            <img
              v-if="item.type == 0"
              :src="item.url"
              @click.prevent="$showPhotoSwipe(post.media, item.id)"
            />
            <div v-else-if="item.type == 1" class="video w-100">
              <video
                ref="video"
                data-video-instance
                class="video-js vjs-big-play-centered"
                controls
                preload="metadata"
                playsinline
                controlsList="nodownload"
                :poster="item.screenshot"
              >
                <source :src="item.url" type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="w-100">
        <img
          v-if="post.media[0].type == 0"
          :src="post.media[0].url"
          @click.prevent="$showPhotoSwipe(post.media, 0)"
        />
        <div v-else-if="post.media[0].type == 1" class="video w-100">
          <video
            ref="video"
            data-video-instance
            class="video-js vjs-big-play-centered"
            controls
            preload="metadata"
            playsinline
            controlsList="nodownload"
            :poster="post.media[0].screenshot"
          >
            <source :src="post.media[0].url" type="video/mp4" />
          </video>
        </div>
      </div>
      <div
        v-if="post.media.length > 1"
        class="switcher rounded text-white px-2 py-1 small"
      >
        <i class="bi-image" /> <span class="current">{{ slide }}</span
        >/{{ post.media.length }}
      </div>
    </div>
    <div class="bg-light w-100" v-else-if="!hasAccess">
      <b-aspect aspect="4:3">
        <div class="w-100 h-100 position-relative">
          <div class="d-flex align-items-center h-100">
            <div class="mx-auto overflow-hidden w-100">
              <img :src="post.media[0].screenshot" class="blurred img-locked"/>
              <b-icon icon="play-circle" class="attachment-play-icon" v-if="post.media[0].type == 1"></b-icon>
            </div>
          </div>
          <div
            class="position-absolute p-3"
            style="bottom: 0; left: 0; right: 0"
          >
            <b-button v-if="isLoggedIn" variant="primary" block @click.prevent="unlock" class="d-flex justify-content-center align-items-center">{{
              post.isFree
                ? $t("general.subscribe-to-see")
                : $t("general.unlock-post-for-x", [post.price])
            }}<ui-icon name="coin" classes="icon-small unlock-item-coin" v-if="!post.isFree"></ui-icon>
            </b-button>
            <b-button v-else variant="primary" block @click="showPublicPagePopup" class="d-flex justify-content-center align-items-center">
              {{
              post.isFree
                  ? $t("general.subscribe-to-see")
                  : $t("general.unlock-post-for-x", [post.price])
              }}
            </b-button>
          </div>
        </div>
      </b-aspect>
    </div>

  </div>
</template>
<style lang="scss">
@import "~@/assets/scss/_variables.scss";
.post {
  .dropdown {
    .btn {
      padding: 0;
    }
  }
  .switcher {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.1);
  }
  .poll {
    .item {
      position: relative;
      .percent {
        z-index: 0;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        & > div {
          background-color: lighten($primary, 40%);
          height: 100%;
          transition: width 0.5s ease-in-out;
        }
      }
      .title {
        position: relative;
        z-index: 1;
      }
    }
  }
  .media {
    max-height: calc(100vh - 100px);
    text-align: center;
    overflow: hidden;
    position: relative;
    .swiper-slide {
      height: unset;
    }
    img {
      cursor: pointer;
      width: 100%;
      display: inline-block;
      max-height: calc(100vh - 100px);
      -o-object-fit: cover;
      object-fit: cover;
      position: relative;
      z-index: 1;
    }
    .video {
      transform: unset;
      top: 0;
      display: flex;
      flex-direction: column;
      align-content: center;
      align-items: center;
      justify-content: center;
      max-height: calc(100vh - 100px);
      position: relative;
      background: #000;
      overflow: hidden;
      z-index: 1;
      .video-js {
        height: 100% !important;
        .vjs-tech {
          height: 100% !important;
        }
      }
    }
  }
}
</style>
<script>
import { Swiper } from "swiper";
import "swiper/swiper-bundle.css";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import Post from "../models/Post";
import Payment from "../models/Payment";
import UiUsername from "./UiUsername.vue";
import UiIcon from "./UiIcon.vue";

export default {
  components: { UiUsername, UiIcon },
  data: function () {
    return {
      slide: 1,
      players: [],
    };
  },
  props: {
    value: Post,
  },
  computed: {
    post: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    isOwner: function () {
      return (
        this.$store.state.currentUser.isAdmin ||
        (this.value && this.value.user.id == this.$store.state.currentUser.id)
      );
    },
    hasAccess: function () {
      return this.$store.state.currentUser.isAdmin || this.post.hasAccess;
    },
    totalVotes() {
      let total = 0;
      for (let p of this.value.poll) {
        total += p.votes_count;
      }
      return total;
    },
    isLoggedIn: function () {
      return this.$store.state.token != null;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      var that = this;
      if (that.$refs.swiper) {
        let s = new Swiper(that.$refs.swiper, {
          // Optional parameters
          direction: "horizontal",
          noSwipingClass: "vjs-control",
        });
        s.on("activeIndexChange", function () {
          that.slide = this.activeIndex + 1;
        });
        s.on("slideChange", function () {
          for (let p of that.players) {
            p.pause();
          }
        });
      }
      if (that.$refs.video) {
        if (Array.isArray(that.$refs.video)) {
          for (let v of that.$refs.video) {
            that.players.push(videojs(v, {}));
          }
        } else {
          that.players.push(videojs(that.$refs.video, {}));
        }
      }
    },
    unlock() {
      if (this.post.isFree && !this.post.user.paid_profile) {
        this.$post(
          "/follow/" + this.post.user.id,
          {},
          (data) => {
            this.$bvToast.toast(data.text, {
              autoHideDelay: 2000,
              variant: 'success',
              title: this.$t("general.success"),
              solid: true,
              toaster: "b-toaster-top-left",
              noCloseButton: true
            });
            
            setTimeout(() => {
              this.$router.push(this.post.user.username);
            }, 1000);
          },
          (errors) => {
            console.log(errors);
          }
        );
        
      } else if(this.post.isFree && this.post.user.paid_profile){
        this.$buyItem({
          type: Payment.TYPE_SUBSCRIPTION_NEW,
          bundle: this.post.user.bundles[0],
          user: this.post.user,
          success: (data) => {
            this.$bvToast.toast(data.message, {
              autoHideDelay: 2000,
              variant: 'success',
              title: this.$t("general.success"),
              solid: true,
              toaster: "b-toaster-top-left",
              noCloseButton: true
            });

            setTimeout(() => {
              this.$router.push(this.post.user.username);
            }, 1000);
          },
        });
      } else {
        this.$buyItem({
          type: Payment.TYPE_POST,
          user: this.post.user,
          post: this.post,
          success: (data) => {
            this.$bvToast.toast(data.message, {
              autoHideDelay: 2000,
              variant: 'success',
              title: this.$t("general.success"),
              solid: true,
              toaster: "b-toaster-top-left",
              noCloseButton: true
            });

            setTimeout(() => {
              this.$router.push(this.post.user.username);
            }, 1000);
          },
        });
      }
    },
    tip() {
      this.$buyItem({
        type: Payment.TYPE_TIP,
        post: this.post,
        user: this.post.user,
      });
    },
    showPublicPagePopup(){
      this.$store.state.showPublicPagePopup = true
    }
  },
};
</script>
