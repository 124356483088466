<template>
  <b-row>
    <b-col>
      <b-row class="bg-white page-header sticky-top">
        <b-col class="px-3 border-bottom">
          <h5 class="text-uppercase my-3" :class="$store.isRTL ? 'text-right' : ''">{{ $t("general.home") }}</h5>
        </b-col>
      </b-row>
      <ui-posts v-model="posts"/>
      <b-row v-if="showEmptyIcon && !$store.state.spinner" class="d-flex justify-content-center flex-column text-center">
        <b-col>
          <img src="../../assets/no-results.png" class="my-5 w-100"/>
        </b-col>
        <b-col>
          <b-link to="search">
            <b-button pill variant="primary"><b-icon icon="search"></b-icon> {{$t('general.search')}}</b-button>
          </b-link>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
import Post from "../models/Post";
import UiPosts from "../ui/UiPosts.vue";

export default {
  data: function () {
    return {
      posts: [],
      page: 1,
      hasMore: false,
      isLoading: false,
      initialized: false,
      sticky: false,
      showEmptyIcon: false
    };
  },
  mounted() {
    this.loadPosts();
    window.addEventListener("scroll", this.updateScroll);
    window.scrollTo(0, 0);
  },
  watch: {
    loadingPercentage: function (newVal) {
      if(newVal >= 100){
        this.initialized = true;
      }
    },
  },
  components: {
    UiPosts
  },
  methods: {
    updateScroll() {
      const scrollPosition = window.innerHeight + window.scrollY;
      if (
        document.body.offsetHeight &&
        scrollPosition &&
        document.body.offsetHeight - scrollPosition <= 1000 &&
        !this.isLoading &&
        this.hasMore
      ) {
        this.loadMore();
      }
    },
    loadPosts() {
      if (this.page == 1) {
        this.$showSpinner();
      }
      this.isLoading = true;
      this.$get(
        "/posts?page=" + this.page,
        (data) => {
          let posts = [...this.posts];
          
          for (let obj of data.data) {
            posts.push(new Post(obj));
          }
          
          this.posts = posts;
          this.hasMore = data.next_page_url != null;
          this.isLoading = false;
          
          if(this.posts.length <= 0){
            this.showEmptyIcon = true;
          }
        },
        (errors) => {
          console.log(errors);
        }
      );
    },
    loadMore() {
      if (this.hasMore) {
        this.page = this.page + 1;
        this.loadPosts();
      }
    },
  },
};
</script>
