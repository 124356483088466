<template>
  <div>
    <b-container>
      <b-row>
        <b-col lg="6" md="9" sm="9" cols="12" class="border-left border-right content">
          <div class="spinner" v-if="spinner">
            <b-spinner variant="secondary" />
          </div>
          <page-user-public></page-user-public>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<style scoped lang="scss">
@import "~@/assets/scss/_variables.scss";
@include media-breakpoint-down(md) {
  .nav {
    text-align: center;
  }
}
@include media-breakpoint-up(lg) {
  .nav {
    text-align: left;
  }
}
a.nav-link {
  font-size: 1.2rem;
  line-height: 1.4rem;
  padding: 0.75rem 1rem;
  vertical-align: middle;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.icon {
  position: relative;
  font-size: 1.4rem;
  line-height: 1.4rem;
  .bi-dot {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 3.5rem;
    line-height: 1;
    margin: -1.8rem;
  }
}
.footer {
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1001;
  padding-bottom: 10px;
  margin: 0;
  padding-right: 10px;
  padding-left: 10px;

  .b-avatar{
    width: 1.8rem !important;
    height: 1.8rem !important;
    background-color: unset !important;
  }
}
.spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
}

.nav-item-text{
  font-family: 'almoni-tzar', sans-serif;
}

.icon-wrapper .icon-small{
  font-size: 32px !important;
}
</style>
<script>

import PageUserPublic from '../../pages/PageUserPublic.vue';

export default {
  components:{
    PageUserPublic
  },
  computed: {
    spinner() {
      return this.$store.state.spinner == true;
    },
  },
};
</script>
