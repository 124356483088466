<template>
   <b-row>
    <b-col>
        <b-row class="border-bottom sticky-top bg-white page-header">
            <div class="d-flex align-items-center w-100 mx-3 text-right">
            <b-link @click="$router.go(-1)">
                <i class="bi-arrow-right" />
            </b-link>
            <h5 class="text-uppercase p-0 my-3 mx-2 flex-grow-1">
                {{ $t("general.add-coin") }}
            </h5>
            </div>
        </b-row>
        <b-row>
            <div class="font-weight-bold text-center w-100 my-5">
            <h3 class="mb-0">{{$t('general.funds-available')}}</h3>
            <div class="d-flex align-items-center justify-content-center mt-3">
                <h1 class="font-weight-bold wallet-total-amount mb-0 mx-2"> {{($store.state.currentUser.wallet.total).toLocaleString()}}</h1>
                <ui-icon name="coin" classes="icon-small"></ui-icon>
            </div>
            </div>
        </b-row>
        <hr>
        <ui-coin-packages class="mt-5" :packages="packages"></ui-coin-packages>
    </b-col>
  </b-row>
</template>


<script>
import UiCoinPackages from '../ui/UiCoinPackages.vue';
import UiIcon from '../ui/UiIcon.vue';

export default{
    data(){
        return{
            packages: null
        }
    },
    components: {
        UiCoinPackages,
        UiIcon
    },
    mounted(){
        this.getCoinPackages();
    },
    methods:{
        getCoinPackages(){
            this.$get('/coin-packages', (data) => {
                this.packages = data.packages;
            });
        }
    }
}
</script>