<template>
  <b-link v-if="asLink" :to="user.url" class="d-flex align-items-center text-dark" :class="$store.isRTL ? 'justify-content-start' : ''">
    <span :class="bold ? 'font-weight-bold' : ''" class="username">{{
      user.name
    }}</span>
    <ui-icon name="verification-badge" :class="$store.isRTL ? 'mr-1' : 'ml-1'" v-if="user.isCreator"></ui-icon>
    <span class="text-muted username" :class="$store.isRTL ? 'mr-1' : 'ml-1'" v-if="full">{{
      "@" + user.username
    }}</span>
  </b-link>
  <div v-else class="d-flex align-items-center" :class="$store.isRTL ? 'justify-content-start' : ''">
    <span :class="bold ? 'font-weight-bold' : ''" class="username">{{
      user.name
    }}</span>
    <ui-icon name="verification-badge" :class="$store.isRTL ? 'mr-1' : 'ml-1'" v-if="user.isCreator"></ui-icon>
    <span class="text-muted username" :class="$store.isRTL ? 'mr-1' : 'ml-1'" v-if="full">{{
      "@" + user.username
    }}</span>
  </div>
</template>
<script>
import User from "../models/User";
import UiIcon from '../ui/UiIcon.vue';

export default {
  components: {
    UiIcon
  },
  props: {
    user: User,
    full: {
      type: Boolean,
      default: false,
    },
    asLink: {
      type: Boolean,
      default: true,
    },
    bold: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
