<template>
    <b-row>
        <b-col cols="6" v-for="(item, index) in packages" :key="index" class="mb-4">
            <div class="package" :class="item.recommended ? 'highlighted': ''" @click="selectPackage(item)">
                <div class="package-header" v-if="item.discount > 0">
                    <span class="package-header-text">{{$t('general.save-discount', [item.discount])}}</span>
                </div>
                <div class="package-body">
                    <b-row class="my-4">
                        <b-col class="d-flex justify-content-center align-items-center">
                            <h2 class="font-weight-bold mb-0 mx-1">{{item.coins.toLocaleString()}}</h2>
                            <ui-icon name="coin" classes="icon-small"></ui-icon>
                        </b-col>
                    </b-row>
                    <b-row class="my-2">
                        <b-col class="d-flex justify-content-center">
                            <h5 class="font-weight-bold">{{$formatAmount(item.price)}}</h5>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </b-col>
    </b-row>
</template>
<style lang="scss" scoped>
.package{
    position: relative;
    &-header{
        background: #BABABA;
        border-radius: 15px;
        position: absolute;
        width: 85%;
        color: white;
        text-align: center;
        padding: 2px;
        top: -10px;
        left: 0;
        right: 0;
        margin-right: auto;
        margin-left: auto;
    }
    &-body{
        padding-top: 30px;
        border: 2px solid #BABABA;
        border-radius: 10px;
    }
}

.highlighted {
    .package{
        &-header{
            background: #E6A71E;
        }
        &-body{
            border: 3px solid #E6A71E;
        }
    }
}

</style>
<script>
import UiIcon from './UiIcon.vue';
import Payment from "../models/Payment";

export default{
    data(){
        return {
            selected: null
        }
    },
    props: ['packages'],
    components:{
        UiIcon
    },
    methods:{
        selectPackage(item){
            this.selected = item;

            this.$buyItem({
            type: Payment.TYPE_DEPOSIT,
            amount: item.price,
            coin_package_id: item.id,
            success: (data) => {
                this.$bvToast.toast(data.message, {
                    autoHideDelay: 2000,
                    variant: 'success',
                    title: this.$t("general.success"),
                    solid: true,
                    toaster: "b-toaster-top-left",
                    noCloseButton: true
                });
            },
            });
        }
    }
}
</script>