<template>
  <div class="rounded user border w-100">
    <div class="header">
      <div class="bg-light cover w-100 position-relative rounded-top">
        <b-img
          :src="user.cover"
          v-if="user.cover != null"
          class="rounded-top"
        />
      </div>
    </div>
    <div class="subprofile w-100 d-flex mb-3">
      <div class="avatar position-relative ml-3 rounded-circle">
        <b-avatar
          :src="user.avatar"
          :text="user.initials"
          :to="user.url"
          size="100px"
        />
      </div>
      <div class="ml-2 mr-3 mt-1 overflow-hidden w-100">
        <ui-username :user="user" />
        <b-link :to="user.url" class="text-muted small username d-block text-right">{{
          "@" + user.username
        }}</b-link>
      </div>
    </div>
    
    <div class="mb-3 px-3" v-if="subscription">
      <b-button variant="primary" size="sm" block @click.prevent="unsubscribe">
        {{
          subscription.isActive
            ? $t("general.subscribed-for-x", [
                !subscription.amount
                  ? $t("general.free")
                  : subscription.priceFormatted,
              ])
            : $t("general.re-subscribe")
        }}
      </b-button>
      <div v-if="subscription.expires && !subscription.isCancelled" class="small text-muted text-center">
        {{ $t("general.expires-on", [subscription.expiresFormatted]) }}
      </div>
      <div v-if="subscription.isCancelled" class="small text-muted text-center">
        {{ $t("general.canceled") }}
      </div>
    </div>
    <b-modal
      centered
      :title="$t('general.unsubscribe')"
      ref="modal"
      :ok-title="$t('general.unsubscribe')"
      @ok="doUnsubscribe()"
    >
      {{ $t("general.are-you-sure-unsubscribe", [user.name]) }}
    </b-modal>
  </div>
</template>
<style lang="scss">
.user {
  .cover {
    height: 80px;
    &:after {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      pointer-events: none;
      content: "";
      border-top-left-radius: 0.25rem !important;
      border-top-right-radius: 0.25rem !important;
    }
    img {
      -o-object-fit: cover;
      object-fit: cover;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
  .buttons {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 11;
    button {
      padding: 0;
    }
  }
  .avatar {
    margin-top: -50px;
  }
}
</style>
<script>
import Subscription from "../models/Subscription";
import User from "../models/User";
import UiUsername from "./UiUsername.vue";
export default {
  components: { UiUsername },
  props: {
    user: User,
    subscription: Subscription,
  },
  methods: {
    unsubscribe() {
      if (this.subscription.isActive) {
        this.$refs["modal"].show();
      } else {
        this.$router.push(this.user.username);
      }
    },
    doUnsubscribe() {
      this.$emit("unsubscribe", this.subscription);
    },
  },
};
</script>
