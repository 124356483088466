<template>
  <div>
    <b-modal
      id="modalCCIL"
      centered
      v-model="visible"
      :title = "title"
      @show="init"
      @cancel.prevent="cancel"
      hide-footer
      ref="modal"
      body-class="p-0"
    >
    <iframe :src="src" name="billing" id="frame1" style="width: 100%;height: 100%;min-height: 400px;border: 0;" target='_parent' />
    <template #modal-header="{ cancel }">
      <!-- Emulate built in modal header close button action -->
      <div class="d-flex align-items-center justify-content-between w-100">
        <h5>{{ $t('general.credit-cart-payment') }}</h5>
        <button class="close" @click="cancel()"><b-icon icon="arrow-left" aria-hidden="true" font-scale="1"></b-icon></button>
      </div>
    </template>
  </b-modal>
  </div>
</template>
<style scoped lang="scss">
.gateway {
  img {
    height: 1.5em;
  }
}
</style>
<script>
export default {
  data: function () {
    return {
      
    };
  },
  computed: {
    src(){
      if(this.$store.state.ccil){
        return this.$store.state.ccil.url;
      }
      return "";
    },
    visible: {
      get() {
        return this.$store.state.ccil != null;
      },
      set() {
        this.$store.state.ccil = null;
      },
    },
    title() {
      if (this.$store.state.ccil) {
        return this.$store.state.ccil.title;
      }
      return "";
    },
  },
  mounted(){
    this.$showSpinner();
  },
  methods: {
    init(){
      this.$hideSpinner();
    },
    cancel(){
      this.visible = false;
      this.$store.state.buyItem = this.$store.state.temp;
      this.$store.state.temp = null;
    }
  },
};
</script>
