<template>
    <div>
      <b-row v-if="items.length > 0">
        <b-table
          thead-class="d-none" 
          class="mx-2"
          borderless
          responsive 
          :items="items"
          :fields="fields"
          :per-page="perPage" 
          :current-page="currentPage" 
          :sort-by.sync="sortBy" 
          :sort-desc.sync="sortDesc" 
          :busy="isBusy"
          @filtered="onFiltered"
        >
        <template #cell(creator)="row">
          <ui-user :user="row.item.user" :subscription="row.item" @unsubscribe="unsubscribe" @subscribe="subscribe"/>
        </template>
        </b-table>
      </b-row>
      <b-row v-else class="my-2">
        <b-col><h3 class="text-muted">{{ $t('general.no-available-data') }}</h3></b-col>
      </b-row>
      <b-row v-if="items.length > 0">
        <div class="d-flex justify-content-between">
          <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="pr-2" />
          <b-form-select v-model="perPage" :options="pageSizes" />
        </div>
      </b-row>
    </div>
</template>

<style lang="scss">

</style>
<script>
import Subscription from '../models/Subscription';
import Payment from '../models/Payment';
import UiUser from './UiUser.vue';

export default {
  data: function () {
    return {
      isBusy: false,
      fields:[
        {key: 'creator', label: this.$t('general.star')}, 
        //{key: 'created_at', label: this.$t('general.date')},
      ],
      items: [],
      totalRows: 0, // total number of items
      perPage: 5, // items per page
      currentPage: 1, // current page number
      sortBy: '', // sort column
      sortDesc: false, // sort direction
      pageSizes: [5, 10, 20] // options for items per page dropdown
    };
  },
  components: {
    UiUser
  },
  watch: {
    currentPage() {
      // load new page of data when currentPage changes
      this.getMySubscriptions();
    },
    perPage() {
      // reset currentPage when perPage changes
      this.currentPage = 1;
    },
    sortBy() {
      // reset currentPage when sortBy changes
      this.currentPage = 1;
    },
    sortDesc() {
      // reset currentPage when sortDesc changes
      this.currentPage = 1;
    }
  },
  created(){
    this.getMySubscriptions();
  },
  methods: {
    getMySubscriptions(){
      this.items = [];
      this.$get(`/subscriptions?page=${this.currentPage}&perPage=${this.perPage}&sortBy=${this.sortBy}&sortDesc=${this.sortDesc}`, (data) => {
        for(let obj of data.data){
          this.items.push(new Subscription(obj));
        }
      },
      (errors) => {
        console.log(errors)
      });

    },
    onFiltered(filteredItems) {
      // handle filter change
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    toggleBusy() {
        this.isBusy = !this.isBusy
    },
    unsubscribe(subscription) {
      this.$post(
        "/subscriptions/" + subscription.id,
        { _method: "DELETE" },

        (data) => {
          if(data.error != null){
            this.$bvToast.toast(data.error, {
              autoHideDelay: 2000,
              title: this.$t("general.error"),
              solid: true,
              variant: "danger",
              toaster: "b-toaster-top-left",
            });
          }else{
            this.$bvToast.toast(data.message, {
              autoHideDelay: 2000,
              title: this.$t("general.success"),
              solid: true,
              variant: "success",
              toaster: "b-toaster-top-left",
            });

            this.getMySubscriptions();

          }
        },
        (errors) => {
          console.log(errors);
        }
      );
    },
    subscribe(subscription) {
      this.$buyItem({
        type: Payment.TYPE_SUBSCRIPTION_RENEW,
        user: subscription.user,
        success: (data)=>{
          this.$nextTick(() => {
            this.$bvToast.toast(data.message, {
              autoHideDelay: 2000,
              variant: 'success',
              title: this.$t("general.success"),
              solid: true,
              toaster: "b-toaster-top-left",
              noCloseButton: true
            });
          });
          
        }
      });
    },
  },
};
</script>