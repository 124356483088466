var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_c('b-col',{staticClass:"chat"},[_c('b-row',{staticClass:"border-bottom sticky-top bg-white page-header"},[_c('div',{staticClass:"d-flex align-items-center w-100 px-3"},[_c('b-link',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('i',{class:_vm.$store.isRTL ? 'bi-arrow-right' : 'bi-arrow-left'})]),_c('h5',{staticClass:"p-0 my-3 mx-2 flex-grow-1 overflow-hidden d-flex"},[_c('b-avatar',{staticClass:"bg-secondary text-white",class:_vm.$store.isRTL ? 'ml-2' : 'mr-2',attrs:{"src":_vm.party ? _vm.party.avatar : '',"to":_vm.party ? _vm.party.url : ''}}),(_vm.party)?_c('ui-username',{attrs:{"user":_vm.party,"asLink":false,"bold":false}}):_c('span',[_vm._v(" ")])],1),(!_vm.isOwner && _vm.$store.state.paymentEnabled)?_c('b-link',{staticClass:"mx-1 my-1 d-flex flex-column",on:{"click":function($event){$event.preventDefault();return _vm.tip.apply(null, arguments)}}},[_c('ui-icon',{attrs:{"name":"coin","classes":"icon-medium"}}),_c('span',{staticClass:"link-text"},[_vm._v(_vm._s(_vm.$t('general.send-tip')))])],1):_vm._e()],1)]),_c('b-row',{ref:"conversation",staticClass:"conversation"},[_c('div',{staticClass:"wrapper px-3"},_vm._l((_vm.messages),function(message,index){return _c('div',{key:index},[(index == _vm.messages.length - 1 ||
              (index < _vm.messages.length - 1 &&
                !_vm.messages[index + 1].created_at.isSame(
                  message.created_at,
                  'day'
                ))
            )?_c('div',{staticClass:"timeline"},[_c('div',{staticClass:"small text-muted bg-white d-inline"},[_vm._v(" "+_vm._s(message.dayRelated)+" ")])]):_vm._e(),(message.type != 'chat-tip' && message.type != 'tip')?_c('ui-message',{attrs:{"index":index,"party":_vm.party},on:{"input":_vm.updateMessage,"completed":_vm.loadedCompleted},model:{value:(_vm.messages[index]),callback:function ($$v) {_vm.$set(_vm.messages, index, $$v)},expression:"messages[index]"}}):_c('div',{staticClass:"timeline my-1"},[_c('div',{staticClass:"medium text-muted bg-white d-inline d-flex"},[_vm._v(" "+_vm._s(message.message)),_c('ui-icon',{attrs:{"name":"coin","classess":"icon-small"}})],1)])],1)}),0)]),_c('b-row',{staticClass:"border-top message-input-container"},[_c('div',{staticClass:"d-flex align-items-center w-100"},[_c('b-form-textarea',{staticClass:"py-0 message-input",attrs:{"rows":"2","max-rows":"4","placeholder":_vm.$t('general.type-message')},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),(_vm.currentUser.isCreator)?_c('b-link',{directives:[{name:"b-modal",rawName:"v-b-modal.modalPrice",modifiers:{"modalPrice":true}}],staticClass:"mx-2 border-0"},[_c('i',{staticClass:"bi-tag"})]):_vm._e(),(_vm.price > 0)?_c('ui-post-option-info',{attrs:{"info":_vm.priceFormat()},on:{"close":function($event){return _vm.priceRemove()}}}):_vm._e(),_c('b-button',{staticClass:"border-0",attrs:{"variant":"outline-light","disabled":_vm.sendingMessage},on:{"click":function($event){$event.preventDefault();return _vm.sendMessage.apply(null, arguments)}}},[_c('ui-icon',{attrs:{"name":"angle-circle-left","classes":"icon-medium"}})],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }