<template>
  <div class="d-flex align-items-center justify-content-center card-wrapper">
    <div class="border rounded shadow-sm w-100">
      <div class="creator-header">
        <div
          :style="'background:url(' + user.cover + ');'"
          class="card-img suggestion-header-bg border-bottom"
          :alt="user.name"
        ></div>
      </div>
      <div class="ml-4 creator-body">
        <div class="row">
          <div class="col-12 col-md-auto">
            <div class="d-flex justify-content-center">
              <img :src="user.avatar" class="avatar rounded-circle shadow" />
            </div>
          </div>
          <div class="pr-2 col-12 col-md-auto">
            <div class="h-50"></div>
            <div class="h-50 d-flex flex-column justify-content-center pt-1">
              <div class="pr-2">
                <div class="m-0 h6 text-truncate d-flex align-items-center">
                  <a href="" class="text-bold d-flex align-items-center">
                    {{ user.name }}
                  </a>
                </div>
                <div class="m-0 text-truncate small">
                  <a href="" class="text-muted">&commat;{{ user.username }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="">
        <div class="my-4 ml-4">
          <ul class="list-unstyled">
            <li class="d-flex align-items-center">
                <ui-icon name="checkmark-sharp"></ui-icon>{{ $t("general.subscribe-benefits-1") }}
            </li>
            <li class="d-flex align-items-center">
                <ui-icon name="checkmark-sharp"></ui-icon> {{ $t('general.subscribe-benefits-2') }}
            </li>
            <li class="d-flex align-items-center">
                <ui-icon name="checkmark-sharp"></ui-icon> {{ $t('general.subscribe-benefits-3') }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.creator-body{
    margin-top:-50px;
}
.creator-body .avatar{
    width: 100px;
    height: 100px;
}
.suggestion-header-bg {
    background-color: #50505047;
    background-position: center center !important;
    background-size: cover !important;
    height: 140px !important;
}
</style>
<script>
import UiIcon from './UiIcon.vue';

export default{
    props: ['user'],
    components: {
        UiIcon
    }
}
</script>
