import dayjs from "dayjs";
import User from "./User";
import Media from "./Media";

var isToday = require('dayjs/plugin/isToday')
var isYesterday = require('dayjs/plugin/isYesterday')
var localizedFormat = require("dayjs/plugin/localizedFormat");

require("dayjs/locale/he");

dayjs.extend(localizedFormat);
dayjs.extend(isToday)
dayjs.extend(isYesterday)
dayjs.locale('he');

export default class Message {

  static TYPE_MESSAGE = 'message';
  static TYPE_TIP = 'tip';
  static TYPE_CHAT_TIP = 'chat-tip';

  id = 0;
  message = "";
  created_at = dayjs();
  user = new User(null);
  isRead = false;
  media = [];
  hasAccess = false;
  price = 0;
  type = Message.TYPE_MESSAGE;
  items = {};
  vue = null;
  lockedMessagePreview = "";
  has_media = false;

  constructor(data, vue = null) {
    if (!data) {
      return;
    }
    this.id = data.id;
    this.message = data.message;
    this.user = data.user != null ? new User(data.user) :  vue.$store.state.currentUser;
    this.isRead = data.read;
    this.hasAccess = data.has_access;
    this.price = data.price;
    this.created_at = dayjs(data.created_at);
    this.type = data.type;
    this.vue = vue;
    this.lockedMessagePreview = data.lockedMessagePreview ?? false;
    this.has_media = data.has_attachments ?? false;
    this.attachmentType = data.attachmentType;

    if (data.attachments && this.hasAccess) {
      for (let m of data.attachments) {
        this.media.push(new Media(m));
      }
    }

    if(data.type == Message.TYPE_TIP || data.type == Message.TYPE_CHAT_TIP){
        if(data.user.id == data.currentUser.id){
          this.message = this.vue.$t('general.sent_x_tip', [data.tip_amount]);
        }else{
          this.message = this.vue.$t('general.receive_x_tip', [data.tip_amount]);
        }
        
    }
  }

  get time() {
    return this.created_at.format('LT');
  }

  get timeRelated() {
    if (this.created_at.isYesterday()) {
      return "general.yesterday";
    }
    return null;
  }

  get timeNormal() {
    if (this.created_at.isToday()) {
      return this.time;
    }
    return this.created_at.format('L');
  }

  get dayRelated() {
    if (this.created_at.isToday()) {
      return this.vue.$t("general.today");
    } else if (this.created_at.isYesterday()) {
      return this.vue.$t("general.yesterday");
    }

    return this.created_at.format('L');
  }

  get messageParsed() {
    const regex = /@([0-9a-z|\-|_]+)/ig;
    return this.message.replace(/[\u00A0-\u9999<>&]/g, function (i) {
      return '&#' + i.charCodeAt(0) + ';';
    }).replace(regex, "<a href='" + process.env.VUE_APP_APP_URL + "/$1'>$&</a>");
  }

  get messageTrimmed() {
    return this.message.length > 20 ? this.message.substring(0, 20) + '...' : this.message;
  }

  get priceFormatted() {
    return process.env.VUE_APP_CURRENCY_SIGN + this.price;
  }

  get isFree() {
    return !this.price && true;
  }

  get images() {
    let c = 0;
    for (let m of this.media) {
      if (m.type == Media.TYPE_IMAGE) {
        c++;
      }
    }
    return c;
  }

  get videos() {
    let c = 0;
    for (let m of this.media) {
      if (m.type == Media.TYPE_VIDEO) {
        c++;
      }
    }
    return c;
  }
}