<template>
    <div class="icon d-flex align-items-center" :class="name+'icon'">
        <div class="icon-wrapper" :class="classes">
            <div class="icon-inner icon-regular">
                <component :is="getIconComponent"></component>
            </div>
        </div>
    </div>
</template>

<style scoped>
.prepend-icon{
  position: absolute;
  left: 0;
  z-index: 1050;
  margin-left: 3px;
}
</style>
<script>
export default{
    props: ['name', 'classes'],
    computed:{
        getIconComponent(){
            return () => import('@/assets/svg/'+this.name+'.svg');
        }
    }
}
</script>