<template>
    <div v-if="user!=null">
        <b-row>
            <div class="bg-light cover w-100 position-relative">
            <b-img :src="user.cover + '?rnd=' + rnd" v-if="user.cover != null" />
            <div class="buttons">
                <b-button
                variant="icon"
                class="p-0 mr-3"
                @click.prevent="clck('cover')"
                >
                <i class="bi-image text-white" />
                </b-button>
                <b-button variant="icon" class="p-0">
                <i class="bi-x-circle text-white" @click.prevent="del('cover')" />
                </b-button>
            </div>
            </div>
            <div class="avatar position-relative ml-3 rounded-circle">
            <b-avatar :src="user.avatar + '?rnd=' + rnd" text=" " size="100px" />
            <div class="buttons d-flex">
                <b-button
                variant="icon"
                class="p-0 mr-3"
                @click.prevent="clck('avatar')"
                >
                <i class="bi-image text-white" />
                </b-button>
                <b-button variant="icon" class="p-0">
                <i
                    class="bi-x-circle text-white"
                    @click.prevent="del('avatar')"
                />
                </b-button>
            </div>
            </div>
        </b-row>
        <b-row>
            <b-form @submit="saveSettings" class="w-100 mx-3 mt-3">
            <ui-form-input
                type="text"
                name="username"
                v-model="user.username"
                :errors="errors"
                :label="$t('general.username')"
            />
            <ui-form-input
                type="text"
                name="name"
                v-model="user.name"
                :errors="errors"
                :label="$t('general.full-name')"
            />
            <ui-form-textarea
                name="bio"
                v-model="user.bio"
                :errors="errors"
                :label="$t('general.bio')"
            />
            <ui-form-date-picker
                name="birthdate"
                v-model="user.birthdate"
                :errors="errors"
                :placeholder="$t('general.set-your-birthdate')"
                :label="$t('general.birthdate')"
                locale="he"
                direction="rtl"
            />
            
            <ui-form-select
              name="gender_id"
              :options="genders"
              v-model="user.gender_id"
              :errors="errors"
              :label="$t('general.gender')"
            />
            <ui-form-input
                type="text"
                name="location"
                v-model="user.location"
                :errors="errors"
                :label="$t('general.location')"
            />
            </b-form>
        </b-row>
        
        <b-row>
          <b-col class="d-flex justify-content-center">
            <b-button primary class="mb-2" @click.prevent="saveSettings">{{$t("general.save")}}</b-button>
          </b-col>
        </b-row>
        <input type="file" ref="avatar" hidden @change="upload('avatar')" />
        <input type="file" ref="cover" hidden @change="upload('cover')" />
    </div>
</template>

<style lang="scss">
.settings {
  .cover,
  .avatar {
    z-index: 2;
    &:after {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      opacity: 0.2;
      pointer-events: none;
      content: "";
      z-index: 3;
    }

    .b-avatar{
      border: 0;
    }
  }
  .cover {
    height: 180px;
    img {
      -o-object-fit: cover;
      object-fit: cover;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
  .buttons {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 11;
  }
  .avatar {
    margin-top: -50px;
    z-index: 3;
    &:after {
      border-radius: 50%;
    }
  }
}
</style>
<script>

import User from "../models/User";
import UiFormInput from "./UiFormInput.vue";
import UiFormSelect from "./UiFormSelect.vue";
import UiFormDatePicker from "./UiFormDatePicker.vue";
import UiFormTextarea from "./UiFormTextarea.vue";

export default{
    data: function () {
    return {
      user: null,
      rnd: Math.random(),
      errors: {},
      genders: [
        {value: 1, text: this.$t('general.male')},
        {value: 2, text: this.$t('general.female')},
        {value: 3, text: this.$t('general.couple')},
        {value: 4, text: this.$t('general.other')},
      ]
    };
  },
  components: {
    UiFormInput,
    UiFormDatePicker,
    UiFormSelect,
    UiFormTextarea,
  },
  mounted() {
    this.loadUser();
  },
  methods: {
    loadUser() {
      this.$showSpinner();

      this.$get(
        "/auth/me",
        (data) => {
          this.user = new User(data);
          this.$hideSpinner();
        },
        (errors) => {
          this.$hideSpinner();
          console.log(errors);
        }
      );
    },
    saveSettings() {
      this.errors = {};
      this.$post(
        "/profile",
        {
          username: this.user.username,
          name: this.user.name,
          bio: this.user.bio,
          location: this.user.location,
          gender_id: this.user.gender_id,
          birthdate: this.user.birthdate
        },

        (data) => {
          this.user = new User(data);
          this.$saveUser(data);
          this.$bvToast.toast(this.$t("general.settings-saved"), {
            autoHideDelay: 2000,
            title: this.$t("general.success"),
            solid: true,
            toaster: "b-toaster-top-left",
          });
        },
        (errors) => {
          this.errors = errors;
        }
      );
    },
    clck(type) {
      this.$refs[type].click();
    },
    upload(type) {
      let formData = new FormData();
      formData.append("image", this.$refs[type].files[0]);
      this.$post(
        "/profile/image/" + type,
        formData,
        (data) => {
          this.user = new User(data);
          this.$saveUser(data);
          this.rnd = Math.random();
        },
        (errors) => {
          console.log(errors);
        }
      );
    },
    del(type) {
      this.$post(
        "/profile/image/" + type,
        {},
        (data) => {
          this.user = new User(data);
          this.$saveUser(data);
          this.rnd = Math.random();
        },
        (errors) => {
          console.log(errors);
        }
      );
    },
  },
}
</script>