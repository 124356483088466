<template>
  <div>
    <b-modal
      id="modalBuy"
      centered
      v-model="visible"
      :title="title"
      @ok.prevent="buy"
      ref="modal"
      @show="init"
      body-class="p-0"
    >
      
      <div class="m-3"> 
        <div class="d-flex mb-3" v-if="item && !isDeposit">
          <b-avatar
            :src="item.user.avatar"
            :text="item.user.initials"
            :to="item.user.url"
          />
          <div class="d-flex flex-column ml-2 overflow-hidden">
            <ui-username :user="item.user" :asLink="false" />
            <span class="text-muted small username">
              {{ "@" + item.user.username }}
            </span>
          </div>
        </div>
        <div class="m-3" v-if="!isTip"><span v-html="description" class="d-flex align-items-center"></span></div>
        <b-row>
          <b-col cols="6">
            <ui-form-input
              type="number"
              name="amount"
              prependIcon="coin"
              v-model="amount"
              classes="rounded-input"
              :errors="errors"
              :placeholder="$t('general.amount')"
              v-if="isTip"
            />
          </b-col>
        </b-row>
      </div>
      <div v-if="method == null && currentUser">
        <div class="m-3">
          <div v-if="errors._ && errors._.length > 0">
            <div
              class="alert alert-danger"
              v-for="error in errors._"
              :key="error"
            >
              {{ error }}
            </div>
          </div>
          <div style="font-size: 1.1rem" v-for="(item, index) in gateways" :key="index" @click="switchGateway(item.id)">
              <div v-if="item.id == 'credit'" class="gateway d-flex my-1 text-black">
                {{ item.name }} <span v-if="item.id=='credit'" class="mr-1">{{ currentUser.wallet.total.toLocaleString() }}</span>
                <ui-icon :name="item.icon" class="icon-small" :class="$store.isRTL ? 'ml-1' : 'mr-1'" ></ui-icon>
                <ui-icon name="checkmark" v-if="gateway == item.id" :class="$store.isRTL ? 'mr-2' : 'ml-2'" ></ui-icon>
              </div>
              <div v-if="item.id == 'ccil'" class="gateway d-flex my-1 text-black">
                <ui-icon :name="item.icon" class="icon-small" :class="$store.isRTL ? 'ml-1' : 'mr-1'" ></ui-icon>
                {{ item.name }} <span v-if="item.id=='credit'" class="mr-1">({{ currentUser.wallet.total.toLocaleString() }})</span>
                <ui-icon name="checkmark" v-if="gateway == item.id" :class="$store.isRTL ? 'mr-2' : 'ml-2'" ></ui-icon>
              </div>
          </div>
        </div>
        <ui-credit-card
          v-if="gateway == 'ccil' && (!isTip || amount)"
          ref="ccil"
          :cc="getCCGateway()"
          :amount="amount"
          :message="message"
          @token="token"
          class="border-top p-3"
        />
      </div>

      <div class="px-2 py-3" v-if="isDeposit || isNewSubscription">
        <span class="text-muted">
          {{ $t('general.buy-note') }}
        </span>
      </div>
      <template #modal-footer="{ ok }">
        <div class="d-flex justify-content-center w-100">
          <b-button size="sm" variant="primary py-2 mx-1" @click.prevent="ok()" v-if="showProceedPaymentButton">
            {{ $t('general.buy-ok') }}
          </b-button>
          <b-button @click="gotoCoinPage" size="sm" variant="warning py-2 mx-1" v-if="showAddCoinLink">
            {{ $t('general.add-coin') }}
          </b-button>
        </div>
      </template>
    </b-modal>
    <ui-icon name="coin" classes="icon-small" class="coin-icon-hidden hidden"></ui-icon>
  </div>
</template>
<style lang="scss">
  #modalBuy{
    font-size: .9rem !important;
  }

  .gateway {
    img {
      height: 1.5em;
    }
    .icon-small{
      font-size: 20px !important;
    }

    &:not(:first-child) {
      border-top: 1px solid #ccc;
      padding-top: 3px;
    }
  }

  .text-black{
    color: black;
    font-weight: 600;
  }
</style>
<script>
import Payment from "../models/Payment";
// import User from "../models/User";
import UiCreditCard from "./UiCreditCard.vue";
import UiFormInput from "./UiFormInput.vue";
import UiUsername from "./UiUsername.vue";
import UiIcon from '../ui/UiIcon.vue';
export default {
  components: { UiCreditCard, UiUsername, UiFormInput, UiIcon },
  data: function () {
    return {
      gateway: 'ccil',
      gateways: [],
      method: null,
      errors: {},
      amount: "",
      message: "",
      showAddCoinLink: false,
      showProceedPaymentButton: true,
      coinIcon: ''
    };
  },
  created(){
    this.$nextTick(() => {
      this.coinIcon = document.getElementsByClassName('coin-icon-hidden')[0].firstElementChild;
      this.coinIcon.style.fontSize = '18px';
    });
  },
  computed: {
    currentUser(){
      return this.$store.state.currentUser;
    },
    currency() {
      return process.env.VUE_APP_CURRENCY_SIGN;
    },
    item() {
      return this.$store.state.buyItem;
    },
    visible: {
      get() {
        return this.$store.state.buyItem != null;
      },
      set() {
        this.$store.state.buyItem = null;
      },
    },
    isTip() {
      return (
        this.$store.state.buyItem &&
        (this.$store.state.buyItem.type == Payment.TYPE_TIP || this.$store.state.buyItem.type == Payment.TYPE_CHAT_TIP)
      );
    },
    isDeposit() {
      return (this.$store.state.buyItem && this.$store.state.buyItem.type == Payment.TYPE_DEPOSIT);
    },
    isNewSubscription() {
      return (this.$store.state.buyItem && this.$store.state.buyItem.type == Payment.TYPE_SUBSCRIPTION_NEW) || (this.$store.state.buyItem && this.$store.state.buyItem.type == Payment.TYPE_SUBSCRIPTION_RENEW);
    },
    title() {
      if (this.$store.state.buyItem) {
        switch (this.$store.state.buyItem.type) {
          case Payment.TYPE_SUBSCRIPTION_NEW:
            return this.$t("general.subscribe");
          case Payment.TYPE_POST:
            return this.$t("general.unlock-post");
          case Payment.TYPE_MESSAGE:
            return this.$t("general.unlock-message");
          case Payment.TYPE_TIP:
          case Payment.TYPE_CHAT_TIP:
            return this.$t("general.send-tip");
          case Payment.TYPE_DEPOSIT:
            return this.$t("general.deposit")
        }
      }
      return "";
    },
    description() {
      if (this.$store.state.buyItem) {
        switch (this.$store.state.buyItem.type) {
          case Payment.TYPE_SUBSCRIPTION_NEW:
            return this.$t("general.subscription-to-x", [
              this.$store.state.buyItem.user.name,
              this.$store.state.buyItem.bundle.price,
              this.$store.state.buyItem.bundle.months
            ]);
          case Payment.TYPE_POST:
            return this.$t("general.unlock-post-from-x-for-y", [
              this.$store.state.buyItem.post.user.name,
              this.$store.state.buyItem.message.price + this.coinIcon.outerHTML,
            ]);
          case Payment.TYPE_MESSAGE:
            return this.$t("general.unlock-message-from-x-for-y", [
              this.$store.state.buyItem.message.user.name,
              this.$store.state.buyItem.message.price + this.coinIcon.outerHTML,
            ]);
        }
      }
      return "";
    },
  },
  methods: {
    init() {
      this.message = "";
      this.amount = "";
      this.showAddCoinLink = false;
      this.showProceedPaymentButton = true;
      this.errors = {};
      this.loadGateways();
    },
    getCCGateway() {
      for (let g of this.gateways) {
        if (g.id == 'ccil') {
          return g;
        }
      }
    },
    loadGateways() {
      this.gateways = [
        {
          id: "ccil",
          name: this.$t('general.payment-by-credit-card'),
          icon: 'credit-card'
        },
        {
          id: "credit",
          name: this.$t('general.payment-by-coin'),
          icon: 'coin'
        }
      ]

      if(this.isDeposit || this.isNewSubscription){
        this.gateways = this.gateways.filter(gateway => gateway.id != 'credit');
      }else{
        this.gateways = this.gateways.filter(gateway => gateway.id != 'ccil');
      }

      this.gateway = this.gateways[0].id;
    },
    switchGateway(gateway){
      this.gateway = gateway;
    },
    token(title, token) {
      this.proceed({
        gateway: "ccil",
        title: title,
        token: token,
      });
    },
    gotoCoinPage(){
      this.$router.push('/coin');
      this.visible = false;
      this.errors = {};
      this.showAddCoinLink = false;
    },
    buy() {
      this.loadGateways();
      this.proceed({ provider: this.gateway });
    },
    proceed(fields) {
      fields.type = this.item.type;
      switch (this.item.type) {
        case Payment.TYPE_SUBSCRIPTION_RENEW:
          fields.transaction_type = this.item.bundle.type;
          fields.recipient_user_id = this.item.user.id;
          fields.amount = this.item.bundle.price;
          break;
        case Payment.TYPE_SUBSCRIPTION_NEW:
          fields.transaction_type = this.item.bundle.type;
          fields.recipient_user_id = this.item.user.id;
          fields.amount = this.item.bundle.price;
          break;
        case Payment.TYPE_POST:
          fields.transaction_type = this.item.type;
          fields.recipient_user_id = this.item.user.id;
          fields.post_id = this.item.post.id;
          fields.amount = this.item.post.price;
          break;
        case Payment.TYPE_MESSAGE:
          fields.user_message_id = this.item.message.id;
          fields.message = this.message;
          fields.amount = this.item.message.price;
          fields.recipient_user_id = this.item.user.id;
          fields.transaction_type = this.item.type;
          break;
        case Payment.TYPE_TIP:
        case Payment.TYPE_CHAT_TIP:
          fields.transaction_type = this.item.type;
          fields.amount = this.amount;
          fields.message = this.message;
          fields.recipient_user_id = this.item.user.id;
          if (this.item.post) {
            fields.post_id = this.item.post.id;
          }
          break;
        case Payment.TYPE_DEPOSIT:
          fields.transaction_type = this.item.type;
          fields.amount = this.item.amount;
          fields.coin_package_id = this.item.coin_package_id;
          break;
      }

      this.$showSpinner();

      this.$post(
        "/payment/initiate",
        fields,
        (data) => {

          if(fields.provider == 'ccil'){
            this.$store.state.temp = this.$store.state.buyItem;
            this.visible = false;
            this.$store.state.ccil = {
              title: this.title,
              type: data.type,
              url: data.url
            }
          }

          if(data.success){
            this.item.success(data);
            this.visible = false;
          }else{
            this.errors = {
              _:[data.message]
            }

            this.showAddCoinLink = true;
            this.showProceedPaymentButton = false;
          }

        },
        (errors) => {
          this.errors = errors;
        }
      );

    },
  },
};
</script>
