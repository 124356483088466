import dayjs from "dayjs";
var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

export default class Media {

    static TYPE_IMAGE = 0;
    static TYPE_VIDEO = 1;
    static TYPE_AUDIO = 2;

    id = '';
    type = Media.TYPE_IMAGE;
    created_at = dayjs();
    url = "";
    screenshot = "";

    constructor(data) {
        this.id = data.id;
        switch(data.attachmentType){
            case 'video':
                this.type = Media.TYPE_VIDEO;
            break;
            case 'image':
                this.type = Media.TYPE_IMAGE;
            break;
            case 'audio':
                this.type = Media.TYPE_AUDIO;
            break;
        }

        this.created_at = dayjs(data.created_at);

        if(!data.locked){
            this.url = data.path;
            this.screenshot = data.thumbnail_path;
        }else{
            this.screenshot = data.previewPath;
        }
        
    }
    
}