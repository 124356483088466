<template>
    <div class="text-right">
      <div v-if="errors._ && errors._.length > 0">
        <div class="alert alert-danger" v-for="error in errors._" :key="error">
          {{ error }}
        </div>
      </div>
  
      <ui-form-input
        type="text"
        name="email"
        v-model="email"
        :errors="errors"
        :label="$t('general.email')"
      />
  
      <ui-form-input
        type="password"
        name="password"
        v-model="password"
        :errors="errors"
        :label="$t('general.password')"
      />
  
      <b-button
        variant="primary"
        class="w-100 mb-3 btn-submit"
        @click.prevent="submitForm"
        >{{ $t("general.login") }}</b-button
      >
  
      <div class="d-flex small align-items-center justify-content-center">
        <b-link @click="$emit('switchForm', {login: false, register: true})">{{ $t("general.create-new-account") }}</b-link>
      </div>
    </div>
  </template>
  <style scoped lang="scss">
  @import "~@/assets/scss/_variables.scss";
  .btn-google {
    background-color: #4285f4;
    color: #ffffff;
    position: relative;
    .icon {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 2.5rem;
      background: #ffffff;
      display: flex;
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
      img {
        display: block;
        margin: auto;
        height: 60%;
      }
    }
  }
  </style>
  <script>
  import User from "../models/User";
  import UiFormInput from "../ui/UiFormInput.vue";
  export default {
    components: { UiFormInput },
    data() {
      return {
        email: "",
        password: "",
        errors: {},
      };
    },
    computed: {
      google() {
        return {
          params: {
            client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
            scope: "profile email",
          },
          renderParams: {
            longtitle: true,
            height: 36,
            theme: "dark",
          },
          icon: require("@/assets/google.svg"),
        };
      },
    },
    methods: {
      submitForm() {
        this.login(User.CHANNEL_EMAIL);
      },
      googleSuccess(googleUser) {
        this.login(
          User.CHANNEL_GOOGLE,
          googleUser.getAuthResponse().access_token
        );
      },
      login(type, token) {
        this.errors = {};
        let fields = {
          channel_type: type,
        };
        if (type == User.CHANNEL_EMAIL) {
          fields.email = this.email;
          fields.password = this.password;
        } else {
          fields.token = token;
        }
  
        if(this.$store.debug){
          fields.XDEBUG_SESSION_START='PHPSTORM';
          console.log(fields);
        }
  
        this.$post(
          "/auth/login",
          fields,
          (data) => {
            this.$saveToken(data.token);
            this.$saveUser(data.user);
          },
          (errors) => {
            this.errors = errors;
          }
        );
      },
      failure() {
        this.$bvToast.toast(this.$t("general.login-failed"), {
          autoHideDelay: 2000,
          title: this.$t("general.error"),
          solid: true,
          toaster: "b-toaster-bottom-left",
        });
      },
    },
  };
  </script>