import { render, staticRenderFns } from "./PageEnableNotification.vue?vue&type=template&id=3da34656&scoped=true&"
import script from "./PageEnableNotification.vue?vue&type=script&lang=js&"
export * from "./PageEnableNotification.vue?vue&type=script&lang=js&"
import style0 from "./PageEnableNotification.vue?vue&type=style&index=0&id=3da34656&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3da34656",
  null
  
)

export default component.exports