<template>
  <b-form-group
    :label="label"
    :label-for="name"
    :description="description"
    class="mb-3"
    label-class="small"
  >
    <b-input-group :prepend="prepend" :append="append">
      <template #prepend v-if="prependIcon">
        <ui-icon :name="prependIcon" classes="icon-small prepend-icon"></ui-icon>
      </template>
      <b-form-input
        :id="name"
        v-model="inputVal"
        :type="type"
        v-mask="mask"
        :state="errors[name] ? false : null"
        :placeholder="placeholder"
        :disabled="disabled"
        :class="classes"
      />
    </b-input-group>
    <b-form-invalid-feedback
      :id="name + '-errors'"
      class="d-block"
      v-if="errors[name] && errors[name].length > 0"
    >
      <div v-for="error in errors[name]" :key="error">
        {{ error }}
      </div>
    </b-form-invalid-feedback>
  </b-form-group>
</template>
<style scoped>
.prepend-icon{
  position: absolute;
  left: 0;
  z-index: 1050;
  margin-left: 3px;
}
.rounded-input{
  border-radius: 30px !important;
}
</style>
<script>
import UiIcon from './UiIcon.vue';

export default {
  props: [
    "label",
    "name",
    "value",
    "errors",
    "type",
    "placeholder",
    "prepend",
    "prependIcon",
    "append",
    "disabled",
    "mask",
    "description",
    "classes"
  ],
  components:{
    UiIcon
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
