import dayjs from "dayjs";
var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

export default class Transaction {

    static STATUS_PENDING = 0;
    static STATUS_COMPLETE = 1;

    id = 0;
    amount = 0;
    status = Transaction.STATUS_PENDING;
    created_at = dayjs();
    type = '';

    constructor(data) {
        this.id = data.id;
        this.status = data.status;
        this.created_at = dayjs(data.created_at).locale('he');
        this.amount = process.env.VUE_APP_CURRENCY_SIGN + (data.amount).toLocaleString();
        this.type = data.type;
    }

}