import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import App from './App.vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import VueClipboard from 'vue-clipboard2'
import VueMask from 'v-mask'
import Misc from './plugins/Misc'
import OneSignalVue from 'onesignal-vue'

import "@/assets/scss/_theming.scss";
import i18n from './i18n'

import routes from './components/helpers/Router';

const router = new VueRouter({
  routes,
  mode: 'history'
});

Vue.use(Vuex)
import stores from './components/helpers/Store';
import './registerServiceWorker'
const store = new Vuex.Store(stores);

Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueClipboard)
Vue.use(Misc);
Vue.use(VueMask);
Vue.use(OneSignalVue);

new Vue({
  router,
  i18n,
  store,
  render: h => h(App),
  beforeMount(){
    this.$OneSignal.init({
      appId: process.env.VUE_APP_ONESIGNAL_APP_ID,
      notifyButton: {
        enable: false // disable the default OneSignal prompt for permission
      }
    });
  }
}).$mount('#app')
