<template>
  <b-row>
    <b-col>
      <b-row class="border-bottom sticky-top bg-white page-header">
        <div class="d-flex justify-content-start align-items-center w-100 mx-3">
            <b-link @click="$router.go(-1)">
              <i class="bi-arrow-right" />
            </b-link>
            <h5 class="text-uppercase p-0 my-3 mx-2">
              {{ $t("general.search") }}
            </h5>
          </div>
      </b-row>
      <AppSuggestions @loaded="onComponentLoaded" class="d-block d-sm-none d-md-none d-lg-none d-xl-none"/>
      <ui-posts v-model="posts" v-if="suggestionLoaded" @reload-user-posts="reloadUserPosts"/>
    </b-col>
  </b-row>
</template>
<script>

import Post from "../models/Post";
import UiPosts from "../ui/UiPosts.vue";
import AppSuggestions from "../layout/AppSuggestions.vue";

export default {
  data: function () {
    return {
      posts: [],
      page: 1,
      hasMore: false,
      isLoading: false,
      q: this.$route.query.q ? this.$route.query.q : '',
      suggestionLoaded: false,
      postsLoaded: false
    };
  },
  mounted() {
    this.loadPosts();
    window.addEventListener("scroll", this.updateScroll);
  },
  components: {
    UiPosts,
    AppSuggestions
  },
  methods: {
    onComponentLoaded(componentName){
      switch(componentName){
        case 'suggestions':
          this.suggestionLoaded = true;
        break;
        case 'posts':
          this.postsLoaded = true;
        break;
      }
    },
    updateScroll() {
      const scrollPosition = window.innerHeight + window.scrollY;
      if (
        document.body.offsetHeight &&
        scrollPosition &&
        document.body.offsetHeight - scrollPosition <= 1000 &&
        !this.isLoading &&
        this.hasMore
      ) {
        this.loadMore();
      }
    },
    reset() {
      this.isLoading = false;
      this.posts = [];
      this.hasMore = false;
      this.page = 1;
      this.loadPosts();
    },
    loadPosts() {
      if (this.page == 1) {
        this.$showSpinner();
      }
      this.isLoading = true;
      this.$get(
        "/posts/search?q=" + encodeURIComponent(this.q) + "&page=" + this.page,
        (data) => {
          let posts = [...this.posts];
          for (let obj of data.data) {
            posts.push(new Post(obj));
          }
          this.posts = posts;
          this.hasMore = data.next_page_url != null;
          this.isLoading = false;
        },
        (errors) => {
          console.log(errors);
        }
      );
    },
    loadMore() {
      if (this.hasMore) {
        this.page = this.page + 1;
        this.loadPosts();
      }
    },
    reloadUserPosts(user){
      for(let [i,obj] of this.posts.entries()){
        if(obj.user.id == user.id){
          this.$get(
            "/posts/show/"+obj.id,
            (data) => {
              this.$set(this.posts, i, new Post(data))
          });
        }
      }
    }
  },
};
</script>
