<template>
    <div>
      <b-row>
        <b-form @submit="saveSettings" class="w-100 mx-3 mt-3">
          <ui-form-input
            type="password"
            name="old_password"
            v-model="oldPassword"
            :errors="errors"
            :label="$t('general.old-password')"
          />
          <ui-form-input
            type="password"
            name="new_password"
            v-model="newPassword"
            :errors="errors"
            :label="$t('general.new-password')"
          />
          <ui-form-input
            type="password"
            name="new_password_confirmation"
            v-model="newPasswordConfirm"
            :errors="errors"
            :label="$t('general.confirm-password')"
          />
        </b-form>
      </b-row>
      <b-row>
        <b-col class="d-flex justify-content-center">
          <b-button primary class="mb-2" @click.prevent="saveSettings">{{$t("general.save")}}</b-button>
        </b-col>
      </b-row>
    </div>
</template>

<style lang="scss">

</style>
<script>
import UiFormInput from "../ui/UiFormInput.vue";
export default {
  data: function () {
    return {
      oldPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
      errors: {},
    };
  },
  components: {
    UiFormInput,
  },
  methods: {
    saveSettings() {
      this.errors = {};
      this.$post(
        "/profile/password",
        {
          old_password: this.oldPassword,
          new_password: this.newPassword,
          new_password_confirmation: this.newPasswordConfirm,
        },
        () => {
          this.$bvToast.toast(this.$t("general.settings-saved"), {
            autoHideDelay: 2000,
            title: this.$t("general.success"),
            solid: true,
            toaster: "b-toaster-bottom-left",
          });

          setTimeout(()=>{
            this.$post("/auth/logout",{},() => {},
              (errors) => {
                console.log(errors);
              }
            );
            this.$saveToken(null);
            this.$saveUser(null);
            this.$router.replace("/");
          }, 500);
        },
        (errors) => {
          this.errors = errors;
        }
      );
    },
  },
};
</script>