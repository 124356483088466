<template>
  <div id="app">
    <router-view></router-view>
    <ui-photo-swipe
      v-if="swiperItems.length > 0"
      :options="swiperOptions"
      :items="swiperItems"
      @close="hidePhotoSwipe"
    />
    <ui-add-to-list />
    <ui-buy />
    <ui-ccil />
  </div>
</template>
<script>
import UiAddToList from "./components/ui/UiAddToList.vue";
import UiBuy from "./components/ui/UiBuy.vue";
import UiCcil from "./components/ui/UiCCIL.vue";
import UiPhotoSwipe from "./components/ui/UiPhotoSwipe.vue";
import Pusher from "pusher-js";
import { useFavicon } from '@vueuse/core'

export default {
  components: { UiPhotoSwipe, UiAddToList, UiBuy, UiCcil },
  computed: {
    swiperItems() {
      return this.$store.state.swiperItems;
    },
    swiperOptions() {
      return this.$store.state.swiperOptions;
    },
    currentUser() {
      return this.$store.state.currentUser;
    },
  },
  mounted() {
    this.isDebugging();
    this.listen();
    this.setRTL();
    this.loadSiteSettings();
    this.setPaymentEnabled();
  },
  watch: {
    currentUser() {
      this.listen();
    }
  },
  methods: {
    hidePhotoSwipe() {
      this.$store.state.swiperItems = [];
    },
    listen() {
      if (this.currentUser) {
        this.$store.state.pusher = new Pusher(
          process.env.VUE_APP_PUSHER_APP_KEY,
          {
            cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
            authEndpoint:
              process.env.VUE_APP_API_URL +
              "/" +
              process.env.VUE_APP_API_VERSION +
              "/authorize-user",
            auth: {
              headers: { Authorization: "Bearer " + this.$store.state.token },
            },
          }
        );
        
        this.$store.state.pusher.subscribe(
          "private-users." + this.currentUser.id
        );

        this.$store.state.pusher.log = function(message) {
          console.log(message);
        };
        this.$store.state.pusher.bind_global((_, data) => {
          if (data.updates) {
            this.$store.state.updates = {
              //notifications: data.updates.notifications,
              messages: data.updates.messages,
            };
          }
        });
      }
    },
    setRTL(){
      if(this.$i18n.locale == 'he'){

        this.$store.isRTL = true;

        document.querySelector('html').classList.add('direction-rtl')
        document.querySelector('html').setAttribute('dir', 'rtl');
        document.querySelector('html').setAttribute('lang', 'he');
      }
    },
    loadSiteSettings(){
      this.$get("/settings",
        (data) => {
          this.$store.state.siteSettings = data;
          
          const favicon = useFavicon();
          favicon.value = data.favicon;

          if(data.custom_css != null){
            // create a new style element and set its content to the custom CSS code
            const style = document.createElement('style');
            style.innerHTML = data.custom_css;

            // append the style element to the head of the document
            document.head.appendChild(style);
          }

          if(data.custom_js != null){
            // create a new DocumentFragment
                const fragment = document.createDocumentFragment();

            // create a new div element
            const divElement = document.createElement('div');

            // set the innerHTML of the div element to the custom JavaScript code
            divElement.innerHTML = data.custom_js;

            // append the div element's child nodes to the fragment
            while (divElement.childNodes.length > 0) {
              fragment.appendChild(divElement.childNodes[0]);
            }

            // insert the fragment into the head section of the document
            document.head.appendChild(fragment);
          }
        },
        (errors) => {
          console.log(errors);
        }
      );
    },
    isDebugging(){
      if(process.env.VUE_APP_DEBUG){
        this.$store.state.debug = true;
      }
    },
    setPaymentEnabled(){
      if(process.env.VUE_APP_PAYMENT_ENABLED == "FALSE"){
        this.$store.state.paymentEnabled = false;
      }else if(process.env.VUE_APP_PAYMENT_ENABLED == "TRUE"){
        this.$store.state.paymentEnabled = true;
      }
    }
  },
};
</script>