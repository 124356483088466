<template>
    <b-row class="settings">
      <b-col>
        <b-row class="border-bottom sticky-top page-header">
          <div class="d-flex align-items-center w-100 mx-3 text-right">
            <b-link @click="$router.go(-1)">
              <i class="bi-arrow-right" />
            </b-link>
            <h5 class="text-uppercase p-0 my-3 mx-2 flex-grow-1">
              {{ $t("general.contact-us") }}
            </h5>
          </div>
        </b-row>
        <b-row>
          <b-form @submit="submitMessage" class="w-100 mx-3 mt-3 text-right p-2">
            <h5 class="pt-3 mb-2">{{ $t('general.contact-description') }}</h5>
            <ui-form-input
              type="email"
              name="email"
              v-model="email"
              :errors="errors"
              :label="$t('general.email')"
            />
            <ui-form-input
              type="text"
              name="subject"
              v-model="subject"
              :errors="errors"
              :label="$t('general.subject')"
            />
            <ui-form-textarea
              name="message"
              rows="6"
              max-rows="10"
              v-model="message"
              :errors="errors"
              :label="$t('general.message')"
            />
          </b-form>
        </b-row>
        <b-row>
          <b-col class="text-center">
            <b-button @click.prevent="submitMessage">{{
              $t("general.save")
            }}</b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </template>
  <script>
  import UiFormTextarea from "../ui/UiFormTextarea.vue";
  import UiFormInput from "../ui/UiFormInput.vue";
  export default {
    data: function () {
      return {
        email: "",
        subject: "",
        message: "",
        errors: {},
      };
    },
    components: {
      UiFormInput,
      UiFormTextarea
    },
    methods: {
      submitMessage() {
        this.errors = {};
        this.$post(
          "/contact/send",
          {
            email: this.email,
            subject: this.subject,
            message: this.message
          },
          (data) => {
            this.$bvToast.toast(data.message, {
              autoHideDelay: 2000,
              title: this.$t("general.success"),
              solid: true,
              toaster: "b-toaster-top-left",
            });

            this.clear();
          },
          (errors) => {
            this.errors = errors;
          }
        );
      },

      clear(){
        this.email = "";
        this.subject = "";
        this.message = "";
      }
    },
  };
  </script>
  