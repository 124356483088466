import Bundle from "./Bundle";

export default class User {

  static ROLE_USER = 0;
  static ROLE_CREATOR = 1;
  static ROLE_ADMIN = 10;

  static CHANNEL_EMAIL = 0;
  static CHANNEL_GOOGLE = 1;

  id = 0;
  name = "";
  username = "";
  avatar = null;
  cover = null;
  role = User.ROLE_USER;
  bio = null;
  location = null;
  website = null;
  price = 0;
  bundles = [];
  isSubscribed = false;
  isFollowed = false;
  wallet = null;
  push_notification_permission = 'default' 
  read_ios_push_notification_instruction = false
  constructor(data) {
    if (!data) {
      return;
    }
    this.id = data.id;
    this.name = data.name;
    this.username = data.username;
    this.avatar = data.avatar;
    this.cover = data.cover;
    this.role = data.role_id;
    this.bio = data.bio ? data.bio : null;
    this.location = data.location ? data.location : null;
    this.website = data.website ? data.website : null;
    this.price = data.profile_access_price;
    this.isSubscribed = data.is_subscribed;
    this.isFollowed = data.is_followed;
    this.wallet = data.wallet;
    this.verified = data.is_verified;
    this.paid_profile = data.paid_profile;
    this.gender = data.gender;
    this.gender_id = data.gender_id;
    this.birthdate = data.birthdate;
    this.push_notification_permission = data.push_notification_permission
    this.read_ios_push_notification_instruction = data.read_ios_push_notification_instruction
    
    this.bundles.push(new Bundle({
      id: 0,
      months: 1,
      price: this.price,
      price_formatted: process.env.VUE_APP_CURRENCY_SIGN + this.price,
      type: 'one-month-subscription'
    }));

    // if(data.profile_access_price_3_months){
    //   this.bundles.push(new Bundle({
    //     id: 0,
    //     months: 3,
    //     price: data.profile_access_price_3_months,
    //     type: 'three-months-subscription'
    //   }));
    // }

    // if(data.profile_access_price_6_months){
    //   this.bundles.push(new Bundle({
    //     id: 2,
    //     months: 6,
    //     price: data.profile_access_price_6_months,
    //     type: 'six-months-subscription'
    //   }));
    // }

    // if(data.profile_access_price_12_months){
    //   this.bundles.push(new Bundle({
    //     id: 3,
    //     months: 12,
    //     price: data.profile_access_price_12_months,
    //     type: 'yearly-subscription'
    //   }));
    // }
  }
  

  get initials() {
    const a = this.username.charAt(0).toUpperCase();
    const b = this.username.charAt(1);
    return a + b;
  }

  get url() {
    return `/${this.username}`;
  }

  get isFree() {
    return !this.paid_profile && true;
  }

  get priceFormatted() {
    return process.env.VUE_APP_CURRENCY_SIGN + this.price;
  }

  get isAdmin() {
    return this.role == User.ROLE_ADMIN;
  }

  get isCreator() {
    return this.verified || this.paid_profile;
  }
}