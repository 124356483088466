<template>
  <img :src="logo_path"/>
</template>

<script>
export default{
  computed:{
    logo_path(){
      let setting = this.$store.state.siteSettings;
      let logo = setting.light_logo; //set as default
      if(setting.default_user_theme == 'dark'){
          logo = setting.dark_logo;
      }
      return logo;
    }
  }
}
</script>