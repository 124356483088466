<template>
  <component :is="layout"></component>
</template>
<script>
import LayoutAuthorized from "./LayoutAuthorized.vue";
import PublicProfile from "./PublicProfile.vue";
import LayoutLanding from "./LayoutLanding.vue";
import LayoutInitialize from './LayoutInitialize.vue';

export default {
  data(){
    return {
      initialize: false
    }
  },
  computed: {
    layout: function(){
      if(this.isLoggedIn && !this.initialize){
        return LayoutAuthorized
      }if(this.isLoggedIn && this.initialize){
        return LayoutInitialize
      }else if(this.isProfile){
        return PublicProfile
      }else{
        return LayoutLanding
      }
    },
    isLoggedIn: function () {
      return this.$store.state.token != null;
    },
    isProfile: function(){
      return this.$route.name == 'profile' ? true : false;
    }
  },
  components: { LayoutAuthorized, PublicProfile, LayoutLanding },
  watch: {
    '$store.state.currentUser': {
      deep: true,
      handler() {
        this.runOneSignalCode()
      }
    }
  },
  beforeMount(){
    if(this.isLoggedIn){
      const vue = this;
      this.runOneSignalCode()
      this.$OneSignal.on('subscriptionChange', function (isSubscribed) {
          if(isSubscribed){
            vue.$OneSignal.getUserId(userId => {
                vue.$post('/push-notification/save-userid', {playerid: userId}, ()=>{
                  vue.initialize = false;
                  vue.$router.push('/')
                });
              });
          }
      });
    }
  },
  created() {
    if (this.isLoggedIn) {
      this.$get(
        "/auth/me",
        (data) => {
          this.$saveUser(data);
        },
        () => {}
      );
    }
  },
  methods:{
    runOneSignalCode: function(){
      const vue = this;
      console.log(navigator.userAgent.toLowerCase());
      this.$OneSignal.isPushNotificationsEnabled(function(isEnabled) {
        if (isEnabled){
          console.log('isEnabled');
          vue.$OneSignal.getNotificationPermission(permission => {
            if(permission != 'denied'){
              vue.$OneSignal.getUserId(userId => {
                vue.$post('/push-notification/save-userid', {playerid: userId});
              });
              vue.initialize = false;
              vue.$router.push('/')
            }
          });
        }else{
          console.log(navigator.userAgent.toLowerCase());
          if(vue.$isAndroid() && vue.$store.state.currentUser.push_notification_permission != 'denied'){
            console.log('android');
            vue.initialize = true;
            vue.$router.push('/enable-notification') 
          }else if(vue.$isIOS()){
            console.log('ios');
            vue.initialize = true;
            vue.$router.push('/ios-instruction') 
          }else{
            vue.$OneSignal.registerForPushNotifications();
            vue.$router.push('/')
          }
        }
      });
    }
  }
};
</script>
