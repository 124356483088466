<template>
    <div class="d-flex justify-content-center flex-column text-center">
        <a @click="skip" class="btn-skip pt-3 pl-3">דלג</a>
        <h5>הורדה מהירה בחינם</h5>
        <icon class="w-100 mx-auto mt-4 mb-2"></icon>
    </div> 
</template>
<style scoped lang="scss">
@import "~@/assets/scss/_variables.scss";
.btn-skip{
    position: absolute;
    top: 0;
    left: 0;
    color: #000;
}
</style>
<script>

import Icon from '@/assets/ios-instruction.svg';

export default {
    components: {
        Icon
    },
    methods: {
        skip: function(){
            this.$get('/push-notification/skip-ios');
            this.$store.state.currentUser.read_ios_push_notification_instruction = 0;
        }
    }
};
</script>