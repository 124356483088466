import dayjs from "dayjs";
import User from "./User";
var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

export default class Subscription {
    id = 0;
    user = null;
    amount = 0;
    expires = dayjs();
    created_at = dayjs();
    status = "";
    constructor(data) {
        this.id = data.id;
        this.user = data.creator ? new User(data.creator) : null;
        this.amount = data.amount;
        this.expires = data.expires_at ? dayjs(data.expires_at) : null;
        this.status = data.status;
        this.created_at = dayjs(data.created_at);
    }

    get priceFormatted() {
        return process.env.VUE_APP_CURRENCY_SIGN + this.amount;
    }

    get expiresFormatted() {
        return this.expires.format('L');
    }

    get isActive() {
        let now = dayjs();
        return now.isBefore(this.expires) && ! this.isCancelled;
    }

    get isExpired() {
        let now = dayjs();
        return now.isAfter(this.expires) && this.status == 'expired';
    }

    get isCancelled() {
        return this.status == 'canceled';
    }

    get status() {
        if (this.isExpired) {
            return "expired";
        } else if (this.isCancelled) {
            return "cancelled";
        } else if (this.isActive) {
            return "active";
        }
        return "unknown";
    }
}