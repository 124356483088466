<template>
  <div class="posts">
    <b-row v-for="(post, key) in posts" :key="key" >
      {{ checkIfLoadedCompleted(key, posts.length - 1) }}
      <ui-post v-model="posts[key]" @reload-user-posts="reloadUserPosts"/>
    </b-row>
    <!-- <div v-if="!(posts.length > 0)">
      <no-content-available/>
      <h5 class="text-center mb-2 mt-2">{{ $t('general.no-posts-available') }}</h5>
    </div> -->
  </div>
</template>
<script>
import UiPost from "../ui/UiPost.vue";
//import NoContentAvailable from '../../assets/svg/no-content-available.svg';

export default {
  props: {
    value: []
  },
  computed: {
    posts: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  components: {
    UiPost,
    //NoContentAvailable
  },
  methods: {
    checkIfLoadedCompleted(currentKey, maxKey){
      if(currentKey == maxKey){
        this.$emit('loaded', true);
      }
    },
    reloadUserPosts(user){
      this.$emit('reload-user-posts', user);
    }
  }
};
</script>
