<template>
    <div>
      <b-row v-if="items.length > 0">
        <b-table
          class="mx-2"
          borderless
          responsive 
          :items="items"
          :fields="fields"
          :per-page="perPage" 
          :current-page="currentPage" 
          :sort-by.sync="sortBy" 
          :sort-desc.sync="sortDesc" 
          :busy="isBusy"
          @filtered="onFiltered"
        >

          <template #cell(user)="row">
            <div class="d-flex">
              <b-avatar
              :src="row.item.avatar ? row.item.avatar : ''"
              :to="row.item.url"
              class="bg-secondary text-white"
              :class="$store.isRTL ? 'ml-2' : 'mr-2'"
            /> 
            <ui-username
              :user="row.item"
              v-if="row.item"
              :asLink="false"
              :bold="false"
            />
            </div>
          </template>
          <template #cell(action)="row">
            <b-button size="sm" variant="primary" @click="unfollow(row.item.id)">{{ $t('general.unfollow') }}</b-button>
          </template>
        </b-table>
      </b-row>
      <b-row v-else class="my-2">
        <b-col><h3 class="text-muted">{{ $t('general.no-available-data') }}</h3></b-col>
      </b-row>
      <b-row v-if="items.length > 0 && lastPage > currentPage">
        <div class="d-flex justify-content-between">
          <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="pr-2" />
          <b-form-select v-model="perPage" :options="pageSizes" />
        </div>
      </b-row>
    </div>
</template>

<style lang="scss">

</style>
<script>
import User from '../models/User';
import UiUsername from './UiUsername.vue';

export default {
  data: function () {
    return {
      isBusy: false,
      fields:[
        {key: 'user', label: this.$t('general.name')},
        {key: 'action', label: ''},
      ],
      items: [],
      totalRows: 0, // total number of items
      perPage: 5, // items per page
      currentPage: 1, // current page number
      sortBy: '', // sort column
      sortDesc: false, // sort direction
      pageSizes: [5, 10, 20], // options for items per page dropdown
      lastPage: 0
    };
  },
  components:{
    UiUsername
  },
  watch: {
    currentPage() {
      // load new page of data when currentPage changes
      this.getFollowedStars();
    },
    perPage() {
      // reset currentPage when perPage changes
      this.currentPage = 1;
    },
    sortBy() {
      // reset currentPage when sortBy changes
      this.currentPage = 1;
    },
    sortDesc() {
      // reset currentPage when sortDesc changes
      this.currentPage = 1;
    }
  },
  created(){
    this.getFollowedStars();
  },
  methods: {
    getFollowedStars(){
      this.items = [];
      this.$get(`/followed?page=${this.currentPage}&perPage=${this.perPage}&sortBy=${this.sortBy}&sortDesc=${this.sortDesc}`, (data) => {
        for(let obj of data.data){
          this.items.push(new User(obj.user));
        }

        this.lastPage = data.last_page;
      },
      (errors) => {
        console.log(errors)
      });

    },
    onFiltered(filteredItems) {
      // handle filter change
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    toggleBusy() {
        this.isBusy = !this.isBusy
    },
    unfollow(userid) {
      this.$post(
        "/follow/" + userid,{},
        (data) => {
          if(data.success){
            this.$bvToast.toast(data.message, {
              autoHideDelay: 2000,
              title: this.$t("general.success"),
              solid: true,
              variant: "success",
              toaster: "b-toaster-top-left",
            });

            //remove user from the table
            this.items = this.items.filter((item)=>{
              return item.id != userid
            });
          }else{
            this.$bvToast.toast(data.error, {
              autoHideDelay: 2000,
              title: this.$t("general.error"),
              solid: true,
              variant: "danger",
              toaster: "b-toaster-top-left",
            });
          }
        },
        (errors) => {
          console.log(errors);
        }
      );
    },
  },
};
</script>