<template>
    <div>
      <b-row v-if="items.length > 0"> 
        <b-table
          class="mx-2 text-right"
          borderless
          responsive 
          :items="items"
          :fields="fields"
          :per-page="perPage" 
          :current-page="currentPage" 
          :sort-by.sync="sortBy" 
          :sort-desc.sync="sortDesc" 
          :busy="isBusy"
          @filtered="onFiltered"
        >
        <template #cell(type)="row">
          <b-badge pill variant="primary">{{$t('general.'+row.item.type)}}</b-badge>
        </template>
        <template #cell(status)="row">
          <b-badge pill :variant="row.item.status == 'approved' ? 'success': 'secondary'">{{$t('general.'+row.item.status)}}</b-badge>
        </template>
        </b-table>
      </b-row>
      <b-row v-else class="my-2">
        <b-col><h3 class="text-muted">{{ $t('general.no-available-data') }}</h3></b-col>
      </b-row>
      <b-row v-if="items.length > 0">
        <div class="d-flex justify-content-between">
          <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="pr-2" />
          <b-form-select v-model="perPage" :options="pageSizes" />
        </div>
      </b-row>
    </div>
</template>

<style lang="scss">

</style>
<script>
//import UiFormInput from "./UiFormInput.vue";
import Transaction from '../models/Transaction';
export default {
  data: function () {
    return {
      isBusy: false,
      fields:[
        {key: 'amount', label: this.$t('general.total')},
        {key: 'status', label: this.$t('general.status')},
        {key: 'type', label: this.$t('general.type'), variant: 'md', tdClass:{'overflow-unset': true}},
        //{key: 'created_at', label: this.$t('general.date')},
      ],
      items: [],
      totalRows: 0, // total number of items
      perPage: 10, // items per page
      currentPage: 1, // current page number
      sortBy: '', // sort column
      sortDesc: false, // sort direction
      pageSizes: [10, 20, 30] // options for items per page dropdown
    };
  },
  watch: {
    currentPage() {
      // load new page of data when currentPage changes
      this.getTransactions();
    },
    perPage() {
      // reset currentPage when perPage changes
      this.currentPage = 1;
    },
    sortBy() {
      // reset currentPage when sortBy changes
      this.currentPage = 1;
    },
    sortDesc() {
      // reset currentPage when sortDesc changes
      this.currentPage = 1;
    }
  },
  created(){
    this.getTransactions();
  },
  methods: {
    getTransactions(){
      this.toggleBusy();
      // make API call to get data
      this.$get(`/transactions?page=${this.currentPage}&perPage=${this.perPage}&sortBy=${this.sortBy}&sortDesc=${this.sortDesc}`, 
      (response) => {
        for(let obj of response.data){
          this.items.push(new Transaction(obj));
        }

        this.totalRows = response.total;
        this.toggleBusy();
      },
      (errors) => {
        console.log(errors);
      });

    },
    onFiltered(filteredItems) {
      // handle filter change
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    toggleBusy() {
        this.isBusy = !this.isBusy
      }
  },
};
</script>