<template>
  <div>
    <b-container>
      <b-row>
        <b-col lg="3" md="2" sm="2" class="d-none d-sm-block">
          <b-nav vertical class="sticky-top side-menu">
            <b-nav-item v-b-toggle.sidebar>
              <b-avatar
                :text="currentUser.initials"
                :src="currentUser.avatar"
                class="bg-secondary text-white"
              />
            </b-nav-item>
            <b-nav-item to="/" exact>
              <div class="icon d-inline-block">
                <UiIcon name="home" classes="icon-small"></UiIcon>
              </div>
              <span class="d-none d-lg-inline ml-3">{{
                $t("general.home")
              }}</span>
            </b-nav-item>
            <b-nav-item to="/search">
              <div class="icon d-inline-block">
                <UiIcon name="search" classes="icon-small"></UiIcon>
              </div>
              <span class="d-none d-lg-inline ml-3">{{
                $t("general.search")
              }}</span>
            </b-nav-item>
            <b-nav-item to="/messages">
              <div class="icon d-inline-block">
                <div class="icon d-inline-block">
                  <UiIcon name="chat" classes="icon-small"></UiIcon>
                </div>
                <span class="pulse pulse-new-message" v-if="updates.messages > 0"></span> 
              </div>
              <span class="d-none d-lg-inline ml-3">{{
                $t("general.messages")
              }}</span>
            </b-nav-item>
            <b-nav-item to="/contact">
              <div class="icon d-inline-block">
                <UiIcon name="faq" classes="icon-small"></UiIcon>
              </div>
              <span class="d-none d-lg-inline ml-3">{{
                $t("general.contact-us")
              }}</span>
            </b-nav-item>
            <b-nav-item :to="currentUser.url" exact>
              <div class="icon d-inline-block"><i class="bi-person" /></div>
              <span class="d-none d-lg-inline ml-3">{{
                $t("general.my-profile")
              }}</span>
            </b-nav-item>
          </b-nav>
        </b-col>
        <b-col 
          :lg="
            $route.name == 'contact' ||
            $route.name == 'messages' ||
            $route.name == 'message' ||
            $route.name == 'chat'
              ? 9
              : 6
          "
          md="9"
          sm="9"
          cols="12"
          class="border-left border-right content"
        >
          <router-view></router-view>
          <div class="spinner" v-if="spinner">
            <b-spinner variant="secondary" />
          </div>
        </b-col>
        <b-col md="3" class="d-none d-lg-block">
          <router-view name="side" class="sticky-top"></router-view>
        </b-col>
      </b-row>
    </b-container>
    <b-nav
      class="
        position-fixed
        footer
        w-100
        bg-white
        border-top
        justify-content-between
        align-items-center
        d-flex d-sm-none
      "
      v-if="showMenu"
    >
      <b-nav-item to="/" exact>
        <UiIcon name="home" classes="icon-small"></UiIcon>
        <span class="nav-item-text mt-1">{{$t('menu.home')}}</span>
      </b-nav-item>
      <b-nav-item to="/search">
        <UiIcon name="search" classes="icon-small"></UiIcon>
        <span class="nav-item-text mt-1">{{$t('menu.search')}}</span>
      </b-nav-item>
      <b-nav-item to="/messages">
        <span class="pulse pulse-new-message" v-if="updates.messages > 0"></span> 
        <UiIcon name="chat" classes="icon-small"></UiIcon>
        <span class="nav-item-text mt-1">{{$t('menu.messages')}}</span>
      </b-nav-item>
      <b-nav-item to="/coin">
        <UiIcon name="wallet" classes="icon-small"></UiIcon>
        <span class="nav-item-text mt-1">{{$t('menu.deposit')}}</span>
      </b-nav-item>
      <b-nav-item v-b-toggle.sidebar>
        <b-avatar :text="currentUser.initials" :src="currentUser.avatar" class="text-white"/>
        <span class="nav-item-text" style="margin-top:1px">{{$t('menu.profile')}}</span>
      </b-nav-item>
    </b-nav>
    <app-menu />
  </div>
</template>
<style scoped lang="scss">
@import "~@/assets/scss/_variables.scss";
@include media-breakpoint-down(md) {
  .nav {
    text-align: center;
  }
}
@include media-breakpoint-up(lg) {
  .nav {
    text-align: left;
  }
}
a.nav-link {
  font-size: 1.2rem;
  line-height: 1.4rem;
  padding: 0.75rem 1rem;
  vertical-align: middle;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.icon {
  position: relative;
  font-size: 1.4rem;
  line-height: 1.4rem;
  .bi-dot {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 3.5rem;
    line-height: 1;
    margin: -1.8rem;
  }
}
.footer {
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1001;
  padding-bottom: 10px;
  margin: 0;
  padding-right: 10px;
  padding-left: 10px;

  .b-avatar{
    width: 1.8rem !important;
    height: 1.8rem !important;
    background-color: unset !important;
  }
}
.spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
}

.nav-item-text{
  font-family: 'almoni-tzar', sans-serif;
}

.icon-wrapper .icon-small{
  font-size: 32px !important;
}
</style>
<script>
import AppMenu from "../AppMenu.vue";
import UiIcon from '../../ui/UiIcon.vue';

export default {
  data(){
    return{
      OneSignal:null
    }
  },
  computed: {
    spinner() {
      return this.$store.state.spinner == true;
    },
    currentUser() {
      return this.$store.state.currentUser;
    },
    updates() {
      return this.$store.state.updates;
    },
    showMenu(){
      if(this.$route.name == 'message'){
        return false;
      }else{
        return true;
      }
    }
  },  
  components: { AppMenu, UiIcon }
};
</script>
