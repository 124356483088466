export default class Bundle {
  id = 0;
  type = '';
  price = 0;
  months = 0;
  user = null;

  constructor(data) {
    if (!data) {
      return;
    }
    this.id = data.id;
    this.type = data.type;
    this.months = data.months;
    this.price = data.price;
  }

  get title() {
    const total = process.env.VUE_APP_CURRENCY_SIGN + this.price;
    return [this.months, total];
  }
}